export const customerVariables = {
  description: "",
  displayName: "Customer variables",
  orderId: 5,
  variables: {
    Basic: {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Basic",
      description: "",
      variables: {
        "Customer ID": {
          description: "Bik customer ID",
          displayName: "Customer ID",
          actualValue: "{{customer.id}}",
          variableType: "number",
          isAvailable: true,
        },
        "Customer first name": {
          description: undefined,
          displayName: "First name",
          actualValue: "{{customer.firstName}}",
          variableType: "string",
          isAvailable: true,
        },
        "Customer name": {
          description: undefined,
          displayName: "Customer name",
          actualValue: "{{customer.name}}",
          variableType: "string",
          isAvailable: false,
        },
        "Customer email ID": {
          description: undefined,
          displayName: "Customer email ID",
          actualValue: "{{customer.email}}",
          variableType: "string",
          isAvailable: false,
        },
        "Customer phone number": {
          description: undefined,
          displayName: "Customer phone number",
          actualValue: "{{customer.phone}}",
          variableType: "string",
          isAvailable: false,
        },
        "Customer tags": {
          description: undefined,
          displayName: "Customer tags",
          actualValue: "{{customer.tags}}",
          variableType: "list",
          needsFetching: true,
          isAvailable: false,
        },
        "Customer Instagram username": {
          description: undefined,
          displayName: "Customer Instagram username",
          actualValue: "{{customer.instagramUsername}}",
          variableType: "string",
          isAvailable: false,
        },
      },
    },
    Address: {
      description: "Customer address",
      displayName: "Address",
      isAvailable: false,
      isSubHeader: true,
      variables: {
        "Customer city": {
          description: undefined,
          displayName: "Customer city",
          actualValue: "{{customer.cityAddress}}",
          variableType: "string",
          isAvailable: false,
        },
        "Customer state": {
          description: undefined,
          displayName: "Customer state",
          actualValue: "{{customer.state}}",
          variableType: "string",
          isAvailable: false,
        },
        "Customer pincode": {
          description: undefined,
          displayName: "Customer pincode",
          actualValue: "{{customer.pincode}}",
          variableType: "string",
          isAvailable: false,
        },
        "Customer full address": {
          description: undefined,
          displayName: "Customer full address",
          actualValue: "{{customer.fullAddress}}",
          variableType: "string",
          isAvailable: false,
        },
      },
    },
  },
};

export const productVariables = {
  description: "",
  displayName: "Product variables",
  orderId: 6,
  variables: {
    Basic: {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Basic",
      description: "Basic Description",
      variables: {
        "Product ID": {
          description: "Shopify product ID",
          displayName: "Product ID",
          actualValue: "{{product.id}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product name": {
          description: undefined,
          displayName: "Product name",
          actualValue: "{{product.name}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product image URL": {
          description: undefined,
          displayName: "Product image URL",
          actualValue: "{{product.photoUrl}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product description": {
          description: undefined,
          displayName: "Product description",
          actualValue: "{{product.description}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product URL": {
          description: undefined,
          displayName: "Product URL",
          actualValue: "{{product.url}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product price": {
          description: undefined,
          displayName: "Product price",
          actualValue: "{{product.price}}",
          variableType: "number",
          isAvailable: false,
        },
        "Product variant ID": {
          description: undefined,
          displayName: "Product variant ID",
          actualValue: "{{product.variantId}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product discount price": {
          description: undefined,
          displayName: "Product discount price",
          actualValue: "{{product.discountPrice}}",
          variableType: "number",
          isAvailable: false,
        },
        "Product quantity": {
          description: undefined,
          displayName: "Product quantity",
          actualValue: "{{product.quantity}}",
          variableType: "number",
          isAvailable: false,
        },
        "Product catalog ID": {
          description: undefined,
          displayName: "Product catalog ID",
          actualValue: "{{product.catalogId}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product SKU ID": {
          description: undefined,
          displayName: "Product SKU ID",
          actualValue: "{{product.skuId}}",
          variableType: "string",
          isAvailable: false,
        },
      },
    },
    Recommendations: {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Recommendations",
      description: "",
      variables: {
        "Similar Products": {
          description: undefined,
          displayName: "Similar Products",
          actualValue: "{{product.similar}}",
          variableType: "list",
          isAvailable: true,
        },
        "Cross Selling Products": {
          description: undefined,
          displayName: "Cross Selling Products",
          actualValue: "{{product.crossSellingProduct}}",
          variableType: "list",
          isAvailable: false,
        },
      },
    },
    Shipment: {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Shipping Details",
      description: "",
      variables: {
        "Product-level tracking URL": {
          description: undefined,
          displayName: "Product-level tracking URL",
          actualValue: "{{product.trackingUrl}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product-level shipment status": {
          description: undefined,
          displayName: "Product-level shipment status",
          actualValue: "{{product.shipmentStatus}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product-level tracking number": {
          description: undefined,
          displayName: "Product-level tracking number",
          actualValue: "{{product.trackingNumber}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product-level tracking company": {
          description: undefined,
          displayName: "Product-level tracking company",
          actualValue: "{{product.trackingCompany}}",
          variableType: "string",
          isAvailable: false,
        },
        "Product fulfillment status": {
          description: undefined,
          displayName: "Product fulfillment status",
          actualValue: "{{product.fulfillmentStatus}}",
          variableType: "string",
          isAvailable: false,
        },
        // "Product variant IDs": {
        //     description: undefined,
        //     displayName: "Product variant IDs",
        //     actualValue: "{{product.variantIds}}",
        //     variableType: "list",
        //     isAvailable: false
        // },
        // "Product type variant": {
        //     description: undefined,
        //     displayName: "Product type variant",
        //     actualValue: "{{product.typeVariant}}",
        //     variableType: "string",
        //     isAvailable: false
        // },
        // "Product offline enabled": {
        //     description: undefined,
        //     displayName: "Product offline enabled",
        //     actualValue: "{{product.offlineEnabled}}",
        //     variableType: "boolean",
        //     isAvailable: false
        // },
        // "Product color variant": {
        //     description: undefined,
        //     displayName: "Product color variant",
        //     actualValue: "{{product.colorVariant}}",
        //     variableType: "string",
        //     isAvailable: false
        // },
        // "Product catalog index": {
        //     description: undefined,
        //     displayName: "Product catalog index",
        //     actualValue: "{{product.catalogIdx}}",
        //     variableType: "string",
        //     isAvailable: false
        // },
        // "Product MRP": {
        //     description: undefined,
        //     displayName: "Product MRP",
        //     actualValue: "{{product.mrp}}",
        //     variableType: "number",
        //     isAvailable: false
        // },
      },
    },
  },
};

export const cartVariables = {
  description: "",
  displayName: "Abandoned cart variables",
  orderId: 7,
  variables: {
    Basic: {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Basic",
      description: "",
      variables: {
        "Abandoned checkout product quantity": {
          description: undefined,
          displayName: "Abandoned checkout product quantity",
          actualValue: "{{cart.productQuantity}}",
          variableType: "number",
          isAvailable: false,
        },
        "Abandoned checkout URL": {
          description: undefined,
          displayName: "Abandoned checkout URL",
          actualValue: "{{cart.cartUrl}}",
          variableType: "string",
          isAvailable: false,
        },
        "Abandoned checkout image URL": {
          description: undefined,
          displayName: "Abandoned checkout image URL",
          actualValue: "{{cart.imageUrl}}",
          variableType: "string",
          isAvailable: false,
        },
        "Abandoned checkout collection names": {
          description: undefined,
          displayName: "Abandoned checkout collection names",
          actualValue: "{{cart.collectionNames}}",
          variableType: "list",
          isAvailable: false,
        },
        "Abandoned checkout collection ids": {
          description: undefined,
          displayName: "Abandoned checkout collection ids",
          actualValue: "{{cart.collectionIds}}",
          variableType: "list",
          isAvailable: false,
        },
        "Abandoned checkout all products name": {
          description: "",
          displayName: "Abandoned checkout all products name",
          actualValue: "{{cart.allProductNames}}",
          variableType: "string",
          isAvailable: false,
        },
        "Abandoned checkout total": {
          description: undefined,
          displayName: "Abandoned checkout total",
          actualValue: "{{cart.total}}",
          variableType: "number",
          isAvailable: false,
        },
        "Abandoned checkout items list": {
            description: "List of items in the abandoned checkout along with productId, VariantId and quantity",
            displayName: "Abandoned checkout items list",
            actualValue: "{{cart.items}}",
            variableType: "list",
            isAvailable: false,
        }
        // "Abandoned checkout converted": {
        //   description: undefined,
        //   displayName: "Abandoned checkout converted",
        //   actualValue: "{{cart.converted}}",
        //   variableType: "boolean",
        //   isAvailable: false,
        // },
      },
    },
    "First Product": {
      isSubHeader: true,
      isAvailable: false,
      displayName: "First Product",
      description: "",
      variables: {
        "Abandoned checkout product name": {
          description: undefined,
          displayName: "Abandoned checkout product name",
          actualValue: "{{cart.productName}}",
          variableType: "string",
          isAvailable: false,
        },
        "Abandoned checkout product price": {
          description: undefined,
          displayName: "Abandoned checkout product price",
          actualValue: "{{cart.productPrice}}",
          variableType: "string",
          isAvailable: false,
        },
        "Abandoned checkout product ID": {
          description: undefined,
          displayName: "Abandoned checkout product ID",
          actualValue: "{{cart.productId}}",
          variableType: "string",
          isAvailable: false,
        },
        "Abandoned checkout first product collection ids": {
          description: undefined,
          displayName: "Abandoned checkout first product collection ids",
          actualValue: "{{cart.firstProductCollectionIds}}",
          variableType: "list",
          isAvailable: false,
        },
        "Abandoned checkout first product collection names": {
          description: undefined,
          displayName: "Abandoned checkout first product collection names",
          actualValue: "{{cart.firstProductCollectionNames}}",
          variableType: "list",
          isAvailable: false,
        },
        //   "Abandoned checkout customer phone number": {
        //     description: undefined,
        //     displayName: "Abandoned checkout customer phone number",
        //     actualValue: "{{cart.phoneNumber}}",
        //     variableType: "string",
        //     isAvailable: false,
        //   },
        //   "Abandoned checkout store ID": {
        //     description: undefined,
        //     displayName: "Abandoned checkout store ID",
        //     actualValue: "{{cart.storeId}}",
        //     variableType: "string",
        //     isAvailable: false,
        //   },
        //   "Abandoned checkout trigger source": {
        //     description: undefined,
        //     displayName: "Abandoned checkout trigger source",
        //     actualValue: "{{cart.triggerSource}}",
        //     variableType: "string",
        //     isAvailable: false,
        //   },
        //   "Abandoned checkout cart ID": {
        //     description: "Shopify abandoned checkout cart ID",
        //     displayName: "Abandoned checkout cart ID",
        //     actualValue: "{{cart.id}}",
        //     variableType: "string",
        //     isAvailable: false,
        //   },
        //   "Abandoned checkout trigger type": {
        //     description: undefined,
        //     displayName: "Abandoned checkout trigger type",
        //     actualValue: "{{cart.triggerType}}",
        //     variableType: "string",
        //     isAvailable: false,
        //   },
      },
    },
  },
};

export const checkoutVariables = {
  description: "",
  displayName: "Add to cart variables",
  orderId: 8,
  variables: {
    "Checkout Process": {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Checkout Process",
      description: "",
      variables: {
        "Add to cart URL": {
          description: undefined,
          displayName: "Add to cart URL",
          actualValue: "{{checkout.checkoutUrl}}",
          variableType: "string",
          isAvailable: false,
        },
        "Add to cart items": {
          description: undefined,
          displayName: "Add to cart items",
          actualValue: "{{checkout.items}}",
          variableType: "list",
          isAvailable: false,
        },
        "Custom checkout items": {
          description: undefined,
          displayName: "Custom checkout items",
          actualValue: "{{checkout.customCheckoutItems}}",
          variableType: "list",
          isAvailable: false,
        },
      },
    },
  },
};

export const orderVariables = {
  description: "",
  displayName: "Order variables",
  orderId: 9,
  variables: {
    Basic: {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Basic",
      description: "",
      variables: {
        "Order name": {
          description: undefined,
          displayName: "Order name",
          actualValue: "{{order.name}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order status page URL": {
          description: undefined,
          displayName: "Order status page URL",
          actualValue: "{{order.url}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order total": {
          description: undefined,
          displayName: "Order total",
          actualValue: "{{order.total}}",
          variableType: "number",
          isAvailable: false,
        },
        "Order product image": {
          description: undefined,
          displayName: "Order product image",
          actualValue: "{{order.productImage}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order product Id": {
          description: undefined,
          displayName: "Order product Id",
          actualValue: "{{order.productId}}",
          variableType: "number",
          isAvailable: false,
        },
        "Order customer name": {
          description: undefined,
          displayName: "Order customer name",
          actualValue: "{{order.customerName}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order customer phone number": {
          description: undefined,
          displayName: "Order customer phone number",
          actualValue: "{{order.phone}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order status": {
          description: undefined,
          displayName: "Order status",
          actualValue: "{{order.status}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order number": {
          description: undefined,
          displayName: "Order number",
          actualValue: "{{order.orderNo}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order ID": {
          description: "Shopify order ID",
          displayName: "Order ID",
          actualValue: "{{order.id}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order source indentifier": {
          description: undefined,
          displayName: "Order source indentifier",
          actualValue: "{{order.sourceIdentifier}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order source name": {
          description: undefined,
          displayName: "Order source name",
          actualValue: "{{order.sourceName}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order tags": {
          description: undefined,
          displayName: "Order tags",
          actualValue: "{{order.tags}}",
          needsFetching: true,
          variableType: "list",
          isAvailable: false,
        },
      },
    },
    "Product Details": {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Product Details",
      description: "",
      variables: {
        "Order product name": {
          description: undefined,
          displayName: "Order product name",
          actualValue: "{{order.productName}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order all product names": {
          description: undefined,
          displayName: "Order all product names",
          actualValue: "{{order.allProductNames}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order item count": {
          description: undefined,
          displayName: "Order item count",
          actualValue: "{{order.itemCount}}",
          variableType: "number",
          isAvailable: false,
        },
        "Order collection names": {
          description: undefined,
          displayName: "Order collection names",
          actualValue: "{{order.collectionNames}}",
          variableType: "list",
          isAvailable: false,
        },
        "Order collection ids": {
          description: undefined,
          displayName: "Order collection ids",
          actualValue: "{{order.collectionIds}}",
          variableType: "list",
          isAvailable: false,
        },
      },
    },
    Address: {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Address",
      description: "",
      variables: {
        "Order shipping address": {
          description: undefined,
          displayName: "Order shipping address",
          actualValue: "{{order.shippingAddress}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order billing address": {
          description: undefined,
          displayName: "Order billing address",
          actualValue: "{{order.billingAddress}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order address": {
          description: undefined,
          displayName: "Order address",
          actualValue: "{{order.address}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order pinCode": {
          description: undefined,
          displayName: "Order pinCode",
          actualValue: "{{order.pinCode}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order city": {
          description: undefined,
          displayName: "Order city",
          actualValue: "{{order.city}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order state": {
          description: undefined,
          displayName: "Order state",
          actualValue: "{{order.state}}",
          variableType: "string",
          isAvailable: false,
        },
      },
    },
    "Payment Details": {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Payment Details",
      description: "",
      variables: {
        "Order payment status": {
          description: undefined,
          displayName: "Order payment status",
          actualValue: "{{order.paymentStatus}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order payment amount": {
          description: undefined,
          displayName: "Order payment amount",
          actualValue: "{{order.paymentAmount}}",
          variableType: "number",
          isAvailable: false,
        },
        "Order payment mode": {
          description: undefined,
          displayName: "Order payment mode",
          actualValue: "{{order.paymentMode}}",
          variableType: "string",
          isAvailable: false,
        },
      },
    },
    Shipment: {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Shipment",
      description: "",
      variables: {
        "Order fulfillment status": {
          description: undefined,
          displayName: "Order fulfillment status",
          actualValue: "{{order.fulfillmentStatus}}",
          variableType: "string",
          isAvailable: false,
        },
        "Order shipping charge": {
          description: undefined,
          displayName: "Order shipping charge",
          actualValue: "{{order.shippingCharge}}",
          variableType: "number",
          isAvailable: false,
        },
        "Item fulfillment status": {
          description: undefined,
          displayName: "Item fulfillment status",
          actualValue: "{{order.productFulfillmentStatus}}",
          variableType: "string",
          isAvailable: false,
        },
        "Item shipment status": {
          description: undefined,
          displayName: "Item shipment status",
          actualValue: "{{order.productShipmentStatus}}",
          variableType: "string",
          isAvailable: false,
        },
        "Item tracking company": {
          description: undefined,
          displayName: "Item tracking company",
          actualValue: "{{order.productTrackingCompany}}",
          variableType: "string",
          isAvailable: false,
        },
        "Item tracking number": {
          description: undefined,
          displayName: "Item tracking number",
          actualValue: "{{order.productTrackingNumber}}",
          variableType: "string",
          isAvailable: false,
        },
        "Item tracking URL": {
          description: undefined,
          displayName: "Item tracking URL",
          actualValue: "{{order.productTrackingUrl}}",
          variableType: "string",
          isAvailable: false,
        },
        // "Order phone number (shipping address)": {
        //     description: undefined,
        //     displayName: "Order phone number (shipping address)",
        //     actualValue: "{{order.shippingAddressPhoneNumber}}",
        //     variableType: "string",
        //     isAvailable: false,
        //   },
        //   "Order phone number (billing address)": {
        //     description: undefined,
        //     displayName: "Order phone number (billing address)",
        //     actualValue: "{{order.billingAddressPhoneNumber}}",
        //     variableType: "string",
        //     isAvailable: false,
        //   },
      },
    },
    // "Product collections": {
    //   isSubHeader: true,
    //   isAvailable: false,
    //   displayName: "Product collections",
    //   description: "Product collections Description",
    //   variables: {
    //     "Order first product collection ids": {
    //       description: undefined,
    //       displayName: "Order first product collection ids",
    //       actualValue: "{{order.firstProductCollectionIds}}",
    //       variableType: "list",
    //       isAvailable: false,
    //     },
    //     "Order first product collection names": {
    //       description: undefined,
    //       displayName: "Order first product collection names",
    //       actualValue: "{{order.firstProductCollectionNames}}",
    //       variableType: "list",
    //       isAvailable: false,
    //     },
    //     "Order product ID": {
    //       description: undefined,
    //       displayName: "Order product ID",
    //       actualValue: "{{order.productId}}",
    //       variableType: "string",
    //       isAvailable: false,
    //     },
    //     "Order store ID": {
    //       description: undefined,
    //       displayName: "Order store ID",
    //       actualValue: "{{order.storeId}}",
    //       variableType: "string",
    //       isAvailable: false,
    //     },
    //     "Order catalog ID": {
    //       description: undefined,
    //       displayName: "Order catalog ID",
    //       actualValue: "{{order.catalogId}}",
    //       variableType: "string",
    //       isAvailable: false,
    //     },
    //     "Order partners data ID": {
    //       description: undefined,
    //       displayName: "Order partners data ID",
    //       actualValue: "{{order.partnersDataId}}",
    //       variableType: "number",
    //       isAvailable: false,
    //     },
    //   },
    // },
    // "Customer details": {
    //   isSubHeader: true,
    //   isAvailable: false,
    //   displayName: "Customer details",
    //   description: "Customer details Description",
    //   variables: {
    //     "Order customer email ID": {
    //       description: undefined,
    //       displayName: "Order customer email ID",
    //       actualValue: "{{order.email}}",
    //       variableType: "string",
    //       isAvailable: false,
    //     },
    //     "Order trigger type": {
    //       description: undefined,
    //       displayName: "Order trigger type",
    //       actualValue: "{{order.triggerType}}",
    //       variableType: "string",
    //       isAvailable: false,
    //     },
    //   },
    // },
  },
};

export const paymentVariables = {
  description: "",
  displayName: "Payment variables",
  orderId: 10,
  variables: {
    "Payment Details": {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Payment Details",
      description: "",
      variables: {
        "Payment link ID": {
          description: undefined,
          displayName: "paymentLink id",
          actualValue: "{{paymentLink.id}}",
          variableType: "string",
          isAvailable: false,
        },
        "Payment amount": {
          description: undefined,
          displayName: "Payment amount",
          actualValue: "{{paymentLink.paymentAmount}}",
          variableType: "number",
          isAvailable: false,
        },
        "Payment link URL": {
          description: undefined,
          displayName: "Payment link URL",
          actualValue: "{{paymentLink.paymentLink}}",
          variableType: "string",
          isAvailable: false,
        },
        "Payment order amount": {
          description: undefined,
          displayName: "Payment order amount",
          actualValue: "{{paymentLink.orderAmount}}",
          variableType: "number",
          isAvailable: false,
        },
        "Payment order ID": {
          description: undefined,
          displayName: "Payment order ID",
          actualValue: "{{paymentLink.orderId}}",
          variableType: "string",
          isAvailable: false,
        },
        "Payment link payment ID": {
          description: undefined,
          displayName: "Payment link payment ID",
          actualValue: "{{paymentLink.paymentId}}",
          variableType: "string",
          isAvailable: false,
        },
        "Payment link status": {
          description: undefined,
          displayName: "Payment link status",
          actualValue: "{{paymentLink.paymentLinkStatus}}",
          variableType: "string",
          isAvailable: false,
        },
        "Payment purpose": {
          description: undefined,
          displayName: "Payment purpose",
          actualValue: "{{paymentLink.purpose}}",
          variableType: "string",
          isAvailable: false,
        },
        //   "Payment store ID": {
        //     description: undefined,
        //     displayName: "Payment store ID",
        //     actualValue: "{{paymentLink.storeId}}",
        //     variableType: "string",
        //     isAvailable: false,
        //   },
      },
    },
  },
};

export const flowVariables = {
  description: "",
  displayName: "Flow variables",
  orderId: 31,
  variables: {
    Basic: {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Basic",
      description: "",
      variables: {
        "FlowVariables flowTriggerInput": {
          description: undefined,
          displayName: "FlowVariables flowTriggerInput",
          actualValue: "{{flowVariables.flowTriggerInput}}",
          variableType: "string",
          isAvailable: false,
        },
        "FlowVariables flowTriggerInputRaw": {
          description: undefined,
          displayName: "FlowVariables flowTriggerInputRaw",
          actualValue: "{{flowVariables.flowTriggeredRawInput}}",
          variableType: "string",
          isAvailable: false,
        },
        "FlowVariables matchingKeyword": {
          description: undefined,
          displayName: "FlowVariables matchingKeyword",
          actualValue: "{{flowVariables.matchingKeyword}}",
          variableType: "string",
          isAvailable: false,
        },
      },
    },
    Contextual: {
      isSubHeader: true,
      isAvailable: false,
      displayName: "Contextual",
      description: "",
      variables: {
        "FlowVariables Post Caption": {
          description: undefined,
          displayName: "FlowVariables Post Caption",
          actualValue: "{{flowVariables.caption}}",
          variableType: "string",
          isAvailable: false,
        },
        "FlowVariables Comment": {
          description: undefined,
          displayName: "FlowVariables Comment",
          actualValue: "{{flowVariables.comment}}",
          variableType: "string",
          isAvailable: false,
        },
        "FlowVariables DM": {
          description: undefined,
          displayName: "FlowVariables DM",
          actualValue: "{{flowVariables.dm}}",
          variableType: "string",
          isAvailable: false,
        },
        "FlowVariables Story Message": {
          description: undefined,
          displayName: "FlowVariables Story Message",
          actualValue: "{{flowVariables.storyMessage}}",
          variableType: "string",
          isAvailable: false,
        },
        "FlowVariables Products": {
          description: undefined,
          displayName: "FlowVariables Products",
          actualValue: "{{flowVariables.products}}",
          variableType: "string",
          isAvailable: false,
        },
      },
    },
  },
};

export const storeVariables = {
  description: "",
  displayName: "Store variables",
  orderId: 37,
  variables: {
    Basic: {
      isSubHeader: true,
      isAvailable: true,
      displayName: "Store variables",
      description: "",
      variables: {
        "Store name": {
          description: undefined,
          displayName: "Store name",
          actualValue: "{{store.name}}",
          variableType: "string",
          isAvailable: true,
        },
        "Store URL": {
          description: undefined,
          displayName: "Store URL",
          actualValue: "{{store.url}}",
          variableType: "string",
          isAvailable: true,
        },
        "Best Selling Products": {
          description: undefined,
          displayName: "Best Selling Products",
          actualValue: "{{store.bestSellingProduct}}",
          variableType: "list",
          isAvailable: true,
        },
        "New Products": {
          description: undefined,
          displayName: "New Products",
          actualValue: "{{store.newProducts}}",
          variableType: "list",
          isAvailable: true,
        },
        "Most Viewed Products": {
          description: undefined,
          displayName: "Most Viewed Products",
          actualValue: "{{store.mostViewedProducts}}",
          variableType: "list",
          isAvailable: true,
        },
        "Store igProfileImage": {
          description: undefined,
          displayName: "Store igProfileImage",
          actualValue: "{{store.igProfileImage}}",
          variableType: "string",
          isAvailable: false,
        },
        "Store ID": {
          description: undefined,
          displayName: "Store ID",
          actualValue: "{{store.id}}",
          variableType: "string",
          isAvailable: true,
        },
      },
    },
  },
};

export const ticketVariables = {
  description: "",
  displayName: "Ticket variables",
  orderId: 39,
  variables: {
    "Ticket id": {
      description: "Id of the ticket created in helpdesk",
      displayName: "Ticket id",
      actualValue: "{{ticket.id}}",
      variableType: "any",
      isAvailable: false,
    },
    Signature: {
      description: "Signature configured in Helpdesk",
      displayName: "Signature",
      actualValue: "{{helpdesk.signature}}",
      variableType: "any",
      isAvailable: false,
    },
  },
};

export const sessionVariables = {
  description: "",
  displayName: "Session variables",
  orderId: 41,
  variables: {
    "Session id": {
      description: undefined,
      displayName: "Session id",
      actualValue: "{{session.id}}",
      variableType: "any",
      isAvailable: true,
    },
    "Session email history": {
      description: undefined,
      displayName: "Session email history",
      actualValue: "{{session.emailHistory}}",
      variableType: "any",
      isAvailable: false,
    },
  },
};

export const customVariables = {
  description: "",
  displayName: "Custom variables",
  orderId: 1,
  variables: {
    "Custom text": {
      description: undefined,
      displayName: "Custom text",
      actualValue: "{{custom.text}}",
      variableType: "any",
      isAvailable: true,
    },
    "Custom url": {
      description: undefined,
      displayName: "Custom url",
      actualValue: "{{custom.url}}",
      variableType: "any",
      isAvailable: true,
    },
  },
};

export const BUSINESS_EVENT_VARIABLES = [
  {
    id: "productId",
    name: "Product Id",
    description:
      "A unique identifier for the product, usually assigned by the inventory system.",
    headerDescription:
      "A unique identifier for the product, usually assigned by the inventory system.",
    type: "string",
    needsFetching: true,
  },
  {
    id: "variantId",
    name: "Variant Id",
    description:
      "The id of the specific variant of the product, such as a different size, color, or model.",
    headerDescription:
      "The id of the specific variant of the product, such as a different size, color, or model.",
    type: "string",
    needsFetching: true,
  },
];
