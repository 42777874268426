import React, { useEffect, useRef, useState } from "react";
import { SectionDivider } from "./AutomationTrigger.style";
import {
  Button,
  COLORS,
  Input,
  BodyCaption,
  Tooltip,
  TitleRegular,
  Floater,
} from "@bikdotai/bik-component-library";
import PlusIcon from "../../icons/plusIcon";
import InfoIcon from "../../icons/infoIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import { CrossIcon } from "../../icons";
import styled from "styled-components";
import { FallbackMessageConfigurationsI } from "./AutomationTrigger";

const FallbackMessageConfigurations: React.FC<{
  showFallbackMessageConfigEmptyError: boolean;
  fallbackMessageConfigurations: any;
  setFallbackMessageConfigurations: React.Dispatch<
    React.SetStateAction<FallbackMessageConfigurationsI>
  >;
}> = ({
  showFallbackMessageConfigEmptyError,
  fallbackMessageConfigurations,
  setFallbackMessageConfigurations,
}) => {
  const fallbackButtonRef = useRef<HTMLDivElement>(null);
  const buttonCustomisationBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonCustomisationBoxRef.current &&
        !buttonCustomisationBoxRef.current.contains(event.target as Node) &&
        fallbackButtonRef.current &&
        !fallbackButtonRef.current.contains(event.target as Node)
      ) {
        setFallbackMessageConfigurations((prev) => ({
          ...prev,
          fallbackButtonCustiomisationBox: false,
        }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <SectionDivider />
      <div>
        <Header>
          <TitleRegular>Configure fallback message</TitleRegular>
          <Tooltip
            body={""}
            tooltipContent={
              <BodyCaption
                color={COLORS.content.primaryInverse}
                style={{ whiteSpace: "pre-line", padding: 8 }}
              >
                Due to Meta's policy, DMs can't be sent unless the user
                interacted with the brand account in the last 7 days. This
                message serves to reopen the 7-days window; the user must
                respond to activate it.
              </BodyCaption>
            }
            width={300}
            placement={"bottom"}
          >
            <TooltipContainer>
              <InfoIcon width={18} height={18} />
            </TooltipContainer>
          </Tooltip>
        </Header>
        <InputContainer>
          <Input
            value={fallbackMessageConfigurations.fallbackMessageTitle}
            onChangeText={(text) => {
              setFallbackMessageConfigurations((prev) => ({
                ...prev,
                fallbackMessageTitle: text,
              }));
            }}
            placeholder="Enter the title"
            labelText="Message title"
            errorMessage={
              showFallbackMessageConfigEmptyError &&
              fallbackMessageConfigurations.fallbackMessageTitle === ""
                ? "Please enter the title"
                : ""
            }
          />
        </InputContainer>

        <InputContainer>
          <Input
            value={fallbackMessageConfigurations.fallbackMessageContent}
            onChangeText={(text) => {
              setFallbackMessageConfigurations((prev) => ({
                ...prev,
                fallbackMessageContent:
                  text.length > 1024 ? text.slice(0, 1024) : text,
              }));
            }}
            placeholder="Enter the message"
            labelText="Message content"
            maxCharLimit={1024}
            maxCharLimitPosition="BOTTOM"
            type="multiline"
            errorMessage={
              showFallbackMessageConfigEmptyError &&
              fallbackMessageConfigurations.fallbackMessageContent === ""
                ? "Please enter the message text"
                : ""
            }
          />
        </InputContainer>

        <div ref={fallbackButtonRef}>
          {fallbackMessageConfigurations.showCustomisedButton ? (
            <CustomButton
              onClick={(e) => {
                e.stopPropagation();
                setFallbackMessageConfigurations((prev) => ({
                  ...prev,
                  fallbackButtonCustiomisationBox: true,
                }));
              }}
            >
              <ButtonText>
                {fallbackMessageConfigurations.fallbackButtonText}
              </ButtonText>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setFallbackMessageConfigurations((prev) => ({
                    ...prev,
                    fallbackButtonCustiomisationBox: false,
                    showCustomisedButton: false,
                    fallbackButtonText: "",
                  }));
                }}
                style={{ cursor: "pointer" }}
              >
                <DeleteIcon width={20} height={22} />
              </div>
            </CustomButton>
          ) : (
            <div style={{ display: "flex" }}>
              <Button
                buttonText={"Add button"}
                LeadingIcon={PlusIcon}
                buttonType={"dashBold"}
                size={"large"}
                matchParentWidth
                onClick={(e) => {
                  e.stopPropagation();
                  setFallbackMessageConfigurations((prev) => ({
                    ...prev,
                    fallbackButtonText: "Get Started!",
                    showCustomisedButton: true,
                    fallbackButtonCustiomisationBox: true,
                  }));
                }}
                error={
                  showFallbackMessageConfigEmptyError &&
                  !fallbackMessageConfigurations.showCustomisedButton
                }
              />
            </div>
          )}
          {showFallbackMessageConfigEmptyError &&
            fallbackMessageConfigurations.fallbackButtonText.length === 0 && (
              <BodyCaption
                style={{ color: COLORS.content.negative, margin: "4px 0 8px" }}
              >
                Button required
              </BodyCaption>
            )}
        </div>
      </div>
      {fallbackMessageConfigurations.fallbackButtonCustiomisationBox && (
        <Floater refElement={fallbackButtonRef} topOffset={-4}>
          <CustomisationBox ref={buttonCustomisationBoxRef}>
            <Arrow />
            <CustomisationHeader>
              <TitleRegular>Button settings</TitleRegular>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setFallbackMessageConfigurations((prev) => ({
                    ...prev,
                    fallbackButtonCustiomisationBox: false,
                  }));
                }}
              >
                <CrossIcon />
              </div>
            </CustomisationHeader>
            <CustomisationContent>
              <Input
                labelText="Button text"
                value={fallbackMessageConfigurations.fallbackButtonText}
                maxCharLimit={20}
                maxCharLimitPosition="BOTTOM"
                onChangeText={(text) => {
                  setFallbackMessageConfigurations((prev) => ({
                    ...prev,
                    fallbackButtonText:
                      text.length > 20 ? text.slice(0, 20) : text,
                  }));
                }}
                placeholder="Enter the button text"
              />
            </CustomisationContent>
          </CustomisationBox>
        </Floater>
      )}
      <SectionDivider />
    </div>
  );
};

export default FallbackMessageConfigurations;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 16px 0;
`;

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div`
  margin: 16px 0;
`;

const CustomButton = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid ${COLORS.stroke.brand};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  line-height: 16px;
  color: ${COLORS.content.secondary};
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const ButtonText = styled.span`
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
`;

const CustomisationBox = styled.div`
  width: 360px;
  height: 186px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  background-color: ${COLORS.surface.standard};
`;

const Arrow = styled.div`
  position: absolute;
  top: 16px;
  left: -14px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 14px solid ${COLORS.surface.subdued};
`;

const CustomisationHeader = styled.div`
  color: ${COLORS.surface.subdued};
  border-bottom: 1px solid ${COLORS.stroke.primary};
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  background-color: ${COLORS.surface.subdued};
  border-radius: 8px 8px 0 0;
`;

const CustomisationContent = styled.div`
  padding: 16px;
`;
