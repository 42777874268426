import { SideModalProps, SubSections } from "../side-modal/sideModal";
import MessageBlock from "../../icons/messageBlock";
import List from "../../icons/list";
import OrdersList from "../../icons/ordersList";
import Person from "../../icons/name";
import Email from "../../icons/email";
import Phone from "../../icons/phone";
import Box from "../../icons/box";
import AddEvent from "../../icons/addEvent";
import Filter from "../../icons/filter";
import ShopifyAddTagIcon from "../../icons/shopifyAddTagIcon";
import FreshdeskCreateTicketIcon from "../../icons/freshdeskCreateTicketIcon";
import RazorpayCreateTicketIcon from "../../icons/razorpayCreateTicketIcon";
import Evaluate from "../../icons/evaluate";
import StoreVariable from "../../icons/storeVariable";
import CallApi from "../../icons/callApi";
import TemplateBlock from "../../icons/templateBlock";
import CatalogList from "../../icons/customDomain";
import MultiProductIcon from "../../icons/multiProductIcon";
import DelayIcon from "../../icons/delayIcon";
import QuestionIcon from "../../icons/questionIcon";
import AddressIcon from "../../icons/addressIcon";
import { JudgeMe } from "../../icons/judgeme";
import Cart from "../../icons/cart";
import Carousel from "../../icons/carousal";
import SmsIcon from "../../icons/sms";
import TagIcon from "../../icons/tagIcon";
import { COLORS } from "@bikdotai/bik-component-library";
import GoswiftIcon from "../../icons/goswiftIcon";
import BrowsingFlowIcon from "../../icons/browsingFlowIcon";
import TicketIcon from "../../icons/ticketIcon";
import ABTestingIcon from "../../icons/ABTestingIcon";
import ConditionIcon from "../../icons/conditionIcon";
import { CsatIcon } from "../../icons/csat";
import ProductCardIcon from "../../icons/productCard";
import WorkingHourIcon from "../../icons/workingHours";
import ShopifyDiscountCodeIcon from "../../icons/shopifyDiscountCodeIcon";
import ShopifyOrderNoteIcon from "../../icons/shopifyOrderNoteIcon";
import ShopifyCustomerTagIcon from "../../icons/shopifyCustomerTagIcon";
import GoogleSheetAdd from "../../icons/google_sheet_add";
import GoogleSheetUpdate from "../../icons/google_sheet_update";
import GoogleSheetGet from "../../icons/google_sheet_add copy";
import OtherActionIcon from "../../icons/OtherActionIcon";
import Reply from "../../icons/reply";
import MediaMessage from "../../icons/mediaMessage";
import MessageIcon from "../../icons/messageIcon";
import OptInOut from "../../icons/optInOut";
import ClassifyIcon from "../../icons/classifyIcon";
import ProductRecommendIcon from "../../icons/productRecommend";
import AiReplyIcon from "../../icons/aiReply";
import { NodeSubType } from "../../models/nodeSubType";
import OrderFilterIcon from "../../icons/orderFilterIcon";
import AiAssistantIcon from "../../icons/aiAssistantIcon";
import AiAskIcon from "../../icons/aiAskIcon";
import OrderDetailsIcon from "../../icons/orderDetailsIcon";
import OrderStatusIcon from "../../icons/orderStatusIcon";

const MANIFEST_BLOCKS: SubSections["listOfBlocks"] = [
  {
    blockIcon: <ClassifyIcon />,
    blockName: "Manifest Search",
    blockId: NodeSubType.MANIFEST_SEARCH,
    description: "Search using manifest",
  },
  {
    blockIcon: <ClassifyIcon />,
    blockName: "Manifest LLM Call",
    blockId: NodeSubType.MANIFEST_LLM_CALL,
    description: "Call LLM using manifest",
  },
  {
    blockIcon: <ClassifyIcon />,
    blockName: "Manifest Exit",
    blockId: NodeSubType.MANIFEST_EXIT,
    description: "Exit using manifest",
  },
  {
    blockIcon: <ClassifyIcon />,
    blockName: "Manifest LLM Input",
    blockId: NodeSubType.MANIFEST_BUILD_LLM_INPUT,
    description: "Build LLM input using manifest",
  },
];

export const sideModalConfigsWhatsapp: {
  [key: string]: Partial<SideModalProps>;
} = {
  Message: {
    header: "Message blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic message",
        listOfBlocks: [
          {
            blockIcon: (
              <MessageBlock color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Message",
            blockId: NodeSubType.MESSAGE,
            description: "Send a non templated Whatsapp message",
          },
          {
            blockIcon: (
              <TemplateBlock color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Template",
            blockId: NodeSubType.TEMPLATE,
            description: "Select from a list of approved Whatsapp templates",
          },
          {
            blockIcon: <Email color={"#FF0000"} />,
            blockName: "Send Email",
            blockId: NodeSubType.EMAIL_TEMP_NEW,
            automationChannel: "WHATSAPP",
            description: "Select from a list of email templates",
          },
          {
            blockIcon: <SmsIcon />,
            blockName: "Send SMS",
            blockId: NodeSubType.SEND_SMS,
            automationChannel: "WHATSAPP",
            description:
              "Select from a list of SMS templates. Requires SMS service provider integration",
          },
          {
            blockIcon: <Carousel color={COLORS.background.positive.vibrant} />,
            blockName: "Carousel",
            blockId: NodeSubType.WA_CAROUSEL,
            automationChannel: "WHATSAPP",
            description: "Carousel",
          },
        ],
      },
      {
        subHeader: "List message",
        listOfBlocks: [
          {
            blockIcon: <List color={COLORS.background.positive.vibrant} />,
            blockName: "List",
            blockId: NodeSubType.LIST,
            description: "Send a Whatsapp list message",
          },
          {
            blockIcon: (
              <CatalogList color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Catalog list",
            blockId: NodeSubType.CATALOG_LIST,
            description: "Send a list of products as Whatsapp list message",
          },
          {
            blockIcon: (
              <OrdersList color={COLORS.background.positive.vibrant} />
            ),
            blockName: "List of orders",
            blockId: NodeSubType.ORDER_LIST,
            description: "Send a list of latest orders (upto 10)",
          },
        ],
      },
      {
        subHeader: "Order message",
        listOfBlocks: [
          {
            blockIcon: (
              <OrderDetailsIcon color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Whatsapp Payment",
            blockId: NodeSubType.WHATSAPP_PAYMENT,
            description:
              "Take payments on whatsapp without letting customers leave the platform",
          },
          {
            blockIcon: (
              <OrderStatusIcon color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Order Confirmation",
            blockId: NodeSubType.ORDER_CONFIRMATION,
            description:
              "Used to send acknowledgement to users after taking a payment on whatsapp",
          },
        ],
      },
      {
        subHeader: "Ask customers",
        listOfBlocks: [
          {
            blockIcon: <Person color={COLORS.background.positive.vibrant} />,
            blockName: "Name",
            blockId: NodeSubType.ASK_NAME,
            automationChannel: "WHATSAPP",
            description: "Ask and save customer name",
          },
          {
            blockIcon: <Email color={COLORS.background.positive.vibrant} />,
            blockName: "Email",
            blockId: NodeSubType.ASK_EMAIL,
            automationChannel: "WHATSAPP",
            description: "Ask and save customer email id",
          },
          {
            blockIcon: <Phone color={COLORS.background.positive.vibrant} />,
            blockName: "Phone number",
            blockId: NodeSubType.ASK_PHONE,
            automationChannel: "WHATSAPP",
            description: "Ask and save customer phone number",
          },
          {
            blockIcon: (
              <QuestionIcon color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Question",
            blockId: NodeSubType.QUESTION,
            automationChannel: "WHATSAPP",
            description:
              "Ask and save any question like pincode, images, video, documents, audio from customer",
          },
          // {
          //   blockIcon: <OrdersList />,
          //   blockName: "Review",
          //   blockId: "Review",
          // },
          // {
          //   blockIcon: <Upload />,
          //   blockName: "Upload Media",
          //   blockId: "Upload Media",
          // },
          {
            blockIcon: <CsatIcon />,
            blockName: "CSAT/NPS",
            blockId: NodeSubType.CSAT_NPS,
            automationChannel: "WHATSAPP",
            description: "Send NPS or CSAT surveys (1-3,1-5 or 1-10 scale)",
          },
          {
            blockIcon: <Email color={"#FF0000"} />,
            blockName: "Email CSAT/NPS",
            blockId: NodeSubType.EMAIL_CSAT,
            automationChannel: "WHATSAPP",
            description:
              "Send NPS or CSAT surveys for Email (1-3,1-5 or 1-10 scale)",
          },
          {
            blockIcon: (
              <AddressIcon color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Address",
            blockId: NodeSubType.WHATSAPP_ADDRESS,
            description: "Ask and save customer address",
          },
        ],
      },
      {
        subHeader: "Product and cart",
        listOfBlocks: [
          // {
          //   blockIcon: <Catalog />,
          //   blockName: "Catalog",
          //   blockId: "catalog",
          // },
          {
            blockIcon: <Box color={COLORS.background.positive.vibrant} />,
            blockName: "Single Product",
            blockId: NodeSubType.SINGLE_PRODUCT,
            description: "Send a single whatsapp catalog message",
          },
          {
            blockIcon: (
              <MultiProductIcon color={COLORS.background.positive.vibrant} />
            ),
            blockName: "Multi Product",
            blockId: NodeSubType.MULTI_PRODUCT,
            description: "Send a multiple whatsapp catalog message",
          },
          {
            blockIcon: <Cart color={COLORS.background.positive.vibrant} />,
            blockName: "Cart info",
            blockId: NodeSubType.CART_INFO,
            description:
              "Send details of the cart created by user as part of end to end checkout flows",
          },
          {
            blockIcon: <ProductCardIcon />,
            blockName: "Product Card",
            blockId: NodeSubType.PRODUCT_CARD,
            description:
              "Send a single product message with image, description and URL",
          },
          {
            blockIcon: <BrowsingFlowIcon />,
            blockName: "Browse Catalog",
            blockId: NodeSubType.BROWSE_COLLECTION,
            description: "Set up browsing flows with products and collections",
          },
          // {
          //   blockIcon: <Cart />,
          //   blockName: "Cart",
          //   blockId: "Cart",
          // },
        ],
      },
    ],
  },
  AI: {
    header: "AI blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic Message",
        listOfBlocks: [
          {
            blockIcon: <ClassifyIcon />,
            blockName: "AI Classify",
            blockId: NodeSubType.AI_CLASSIFY,
            description: "Classify customer messages using AI",
          },
          {
            blockIcon: <AiReplyIcon />,
            blockName: "AI Reply",
            blockId: NodeSubType.AI_REPLY,
            description: "Reply to customer messages using AI",
          },
          {
            blockIcon: <ProductRecommendIcon />,
            blockName: "AI Product Recommend",
            blockId: NodeSubType.AI_PRODUCT_RECOMMEND,
            description: "Products recommended using AI",
          },
          {
            blockIcon: <AiAssistantIcon />,
            blockName: "AI Assistant",
            blockId: NodeSubType.AI_GENERAL_PURPOSE_ASSISTANT,
            description:
              "AI-powered support assistant: Fast, intelligent answers to customer’s query.",
          },
          {
            blockIcon: <AiAskIcon />,
            blockName: "AI Ask",
            blockId: NodeSubType.AI_ASK,
            description: "Collect answers to multiple questions using AI",
          },
        ],
      },
      {
        subHeader: "Manifest blocks",
        listOfBlocks: MANIFEST_BLOCKS,
      },
    ],
  },
  Action: {
    header: "Action blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic actions",
        listOfBlocks: [
          {
            blockIcon: <AddEvent color={COLORS.background.negative.vibrant} />,
            blockName: "Add Event",
            blockId: NodeSubType.ADD_EVENT,
            description:
              "Add event in BIK data platform which can be then later used for analytics or segmentation",
          },
          {
            blockIcon: <OptInOut color={COLORS.background.negative.vibrant} />,
            blockName: "Opt In/Out",
            blockId: NodeSubType.BROADCAST_EVENTS,
            description:
              "Take an action to opt in / out a customer from Whatsapp. Email unsubscribe happens on link click",
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Assign Ticket",
            blockId: NodeSubType.ASSIGN_TICKET,
            description:
              "Assign tickets to a specific agent or team with a label (optional)",
          },
          {
            blockIcon: <TagIcon color={COLORS.background.negative.vibrant} />,
            blockName: "Add Ticket Label",
            blockId: NodeSubType.ADD_TICKET_LABEL,
            description: "Add a label to tickets",
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Change Ticket Stage",
            blockId: NodeSubType.CHANGE_TICKET_STAGE,
            description:
              "Change the ticket stage to close/pending or any custom stage as defined",
          },
          {
            blockIcon: <WorkingHourIcon />,
            blockName: "Working Hour Check",
            blockId: NodeSubType.CHECK_WORKING_HOURS,
            description:
              "Check if the time falls within working hour and accordingly define actions such as agent assign etc. ",
          },
          // {
          //   blockIcon: <Email />,
          //   blockName: "Send Email",
          //   blockId: "send_email",
          // },
          // {
          //   blockIcon: <SmsIcon />,
          //   blockName: "Send Sms",
          //   blockId: "send_sms",
          // },
          // {
          //   blockIcon: <BackTrack />,
          //   blockName: "Prevent backtracking",
          //   blockId: "prevent_backtracking",
          // },
        ],
      },
      {
        subHeader: "Shopify",
        listOfBlocks: [
          {
            blockIcon: <ShopifyDiscountCodeIcon />,
            blockName: "Discount Code",
            blockId: NodeSubType.DISCOUNT_CODE,
            description: "Create static or dynamic discount codes on shopify",
          },
          {
            blockIcon: <ShopifyAddTagIcon />,
            blockName: "Add Order Tags",
            blockId: NodeSubType.SHOPIFY_ADD_TAGS,
            description: "Add a tag against the order in shopify",
          },
          {
            blockIcon: <ShopifyOrderNoteIcon />,
            blockName: "Add Order Notes",
            blockId: NodeSubType.SHOPIFY_ADD_NOTES,
            description: "Add a note against the order in shopify",
          },
          {
            blockIcon: <ShopifyCustomerTagIcon />,
            blockName: "Add Customer Tags",
            blockId: NodeSubType.ADD_CUSTOMER_TAGS,
            description:
              "Add a customer tag against the order on shopify and BIK",
          },
        ],
      },
      {
        subHeader: "Integrations",
        listOfBlocks: [
          {
            blockIcon: <FreshdeskCreateTicketIcon />,
            blockName: "Freshdesk: Create Ticket",
            blockId: NodeSubType.CREATE_TICKET_ON_FRESHDESK,
            description:
              "Create a ticket on Freshdesk. This requires freshdesk integration",
          },
          {
            blockIcon: <RazorpayCreateTicketIcon />,
            blockName: "Razorpay: Payment Link",
            blockId: NodeSubType.CREATE_PAYMENT_LINK_ON_RAZORPAY,
            description: "Create a payment link from razorpay",
          },
          {
            blockIcon: <GoswiftIcon />,
            blockName: "Goswift: Payment Link",
            blockId: NodeSubType.CREATE_PAYMENT_LINK_ON_GOSWIFT,
            description: "Create a payment link from Goswift",
          },
          {
            blockIcon: <JudgeMe />,
            blockName: "Judge Me",
            blockId: NodeSubType.JUDGEME,
            description: "Create a review on Judge me",
          },
        ],
      },
      {
        subHeader: "Google Sheets",
        listOfBlocks: [
          {
            blockIcon: <GoogleSheetAdd />,
            blockName: "Add row",
            blockId: NodeSubType.GOOGLE_SHEET_ADD_ROW,
            description: "Add a new row on google sheet",
          },
          {
            blockIcon: <GoogleSheetUpdate />,
            blockName: "Update row",
            blockId: NodeSubType.GOOGLE_SHEET_UPDATE_ROW,
            description: "Update an existing row on google sheet",
          },
          {
            blockIcon: <GoogleSheetGet />,
            blockName: "Get row data",
            blockId: NodeSubType.GOOGLE_SHEET_GET_ROW_DATA,
            description:
              "Get all column values for a particular row in google sheet",
          },
        ],
      },
      {
        subHeader: "Special actions",
        listOfBlocks: [
          {
            blockIcon: (
              <StoreVariable color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Store to variable",
            blockId: NodeSubType.STORE_VARIABLE,
            description:
              "Copy value of a variable / expression to another variable",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Rest API",
            blockId: NodeSubType.REST_API,
            description:
              "Call a rest api (GET/POST/PUT) to send or receive data",
          },
          {
            blockIcon: <OtherActionIcon />,
            blockName: "Other Actions",
            blockId: NodeSubType.CALL_CUSTOM_API,
            description: "Call a set of pre defined apis in BIK's system",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Call an API",
            blockId: NodeSubType.CALL_API,
            description: "Use this for generating cart and cart links",
          },
        ],
      },
    ],
  },
};

export const sideModalConfigsInstagram: {
  [key: string]: Partial<SideModalProps>;
} = {
  Message: {
    header: "Message blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic message",
        listOfBlocks: [
          {
            blockIcon: <MessageIcon color="#510058" />,
            blockName: "Message",
            blockId: NodeSubType.INSTAGRAM_TEXT,
            description: "Send a simple Instagram message",
          },
          {
            blockIcon: <MediaMessage color="#510058" />,
            blockName: "Media",
            blockId: NodeSubType.INSTAGRAM_MEDIA,
            description: "Send media messages on Instagram",
          },
          {
            blockIcon: <Carousel height={20} width={20} color={"#212121"} />,
            blockName: "Carousel",
            blockId: NodeSubType.CAROUSEL,
            description: "Send carousel/slider message on Instagram",
          },
          {
            blockIcon: <Box color="#510058" />,
            blockName: "Product",
            blockId: NodeSubType.PRODUCT_CAROUSEL,
            description: "Send one or multiple products on Instagram",
          },
          {
            blockIcon: <OrdersList color="#510058" />,
            blockName: "List of orders",
            blockId: NodeSubType.ORDER_LIST,
            description: "Send a list of latest orders (upto 10)",
          },
          {
            blockIcon: <Email color={"#FF0000"} />,
            blockName: "Send Email",
            blockId: NodeSubType.EMAIL_TEMP_NEW,
            automationChannel: "INSTAGRAM",
            description: "Select from a list of email templates",
          },
          {
            blockIcon: <SmsIcon />,
            blockName: "Send SMS",
            blockId: NodeSubType.SEND_SMS,
            automationChannel: "INSTAGRAM",
            description:
              "Select from a list of SMS templates. Requires SMS service provider integration",
          },
        ],
      },
      {
        subHeader: "Ask customers",
        listOfBlocks: [
          {
            blockIcon: <Person color="#510058" />,
            blockName: "Name",
            blockId: NodeSubType.ASK_NAME,
            automationChannel: "INSTAGRAM",
            description: "Ask and save customer name",
          },
          {
            blockIcon: <Email color="#510058" />,
            blockName: "Email",
            blockId: NodeSubType.ASK_EMAIL,
            automationChannel: "INSTAGRAM",
            description: "Ask and save customer email id",
          },
          {
            blockIcon: <Phone color="#510058" />,
            blockName: "Phone number",
            blockId: NodeSubType.ASK_PHONE,
            automationChannel: "INSTAGRAM",
            description: "Ask and save customer phone number",
          },
          {
            blockIcon: <QuestionIcon color="#510058" />,
            blockName: "Question",
            blockId: NodeSubType.QUESTION,
            automationChannel: "INSTAGRAM",
            description:
              "Ask and save any question like pincode, images, video, documents, audio from customer",
          },
          {
            blockIcon: <CsatIcon color="#510058" />,
            blockName: "CSAT",
            blockId: NodeSubType.CSAT,
            automationChannel: "INSTAGRAM",
            description: "Send NPS or CSAT surveys (1-3,1-5 or 1-10 scale)",
          },
          {
            blockIcon: <Email color={"#FF0000"} />,
            blockName: "Email CSAT/NPS",
            blockId: NodeSubType.EMAIL_CSAT,
            automationChannel: "WHATSAPP",
            description:
              "Send NPS or CSAT surveys for Email (1-3,1-5 or 1-10 scale)",
          },
        ],
      },
      {
        subHeader: "Product and cart",
        listOfBlocks: [
          {
            blockIcon: <Cart color="#510058" />,
            blockName: "Cart info",
            blockId: NodeSubType.CART_INFO,
            description:
              "Send details of the cart created by user as part of end to end checkout flows",
          },
          {
            blockIcon: <BrowsingFlowIcon color="#510058" />,
            blockName: "Browse Catalog",
            blockId: NodeSubType.BROWSE_COLLECTION,
            description: "Set up browsing flows with products and collections",
          },
        ],
      },
    ],
  },
  AI: {
    header: "AI blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic Message",
        listOfBlocks: [
          {
            blockIcon: <ClassifyIcon />,
            blockName: "AI Classify",
            blockId: NodeSubType.AI_CLASSIFY,
            description: "Classify customer messages using AI",
          },
          {
            blockIcon: <AiReplyIcon />,
            blockName: "AI Reply",
            blockId: NodeSubType.AI_REPLY,
            description: "Reply to customer messages using AI",
          },
          {
            blockIcon: <ProductRecommendIcon />,
            blockName: "AI Product Recommend",
            blockId: NodeSubType.AI_PRODUCT_RECOMMEND,
            description: "Products recommended using AI",
          },
          {
            blockIcon: <AiAssistantIcon />,
            blockName: "AI Assistant",
            blockId: NodeSubType.AI_GENERAL_PURPOSE_ASSISTANT,
            description:
              "AI-powered support assistant: Fast, intelligent answers to customer’s query",
          },
          {
            blockIcon: <AiAskIcon />,
            blockName: "AI Ask",
            blockId: NodeSubType.AI_ASK,
            description: "Collect answers to multiple questions using AI",
          },
        ],
      },
      {
        subHeader: "Manifest blocks",
        listOfBlocks: MANIFEST_BLOCKS,
      },
    ],
  },
  Action: {
    header: "Action blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic actions",
        listOfBlocks: [
          {
            blockIcon: <AddEvent color={COLORS.background.negative.vibrant} />,
            blockName: "Add Event",
            blockId: NodeSubType.ADD_EVENT,
            description:
              "Add event in BIK data platform which can be then later used for analytics or segmentation",
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Assign Ticket",
            blockId: NodeSubType.ASSIGN_TICKET,
            description:
              "Assign tickets to a specific agent or team with a label (optional)",
          },
          {
            blockIcon: <TagIcon />,
            blockName: "Add Ticket Label",
            blockId: NodeSubType.ADD_TICKET_LABEL,
            description: "Add a label to tickets",
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Change Ticket Stage",
            blockId: NodeSubType.CHANGE_TICKET_STAGE,
            description:
              "Change the ticket stage to close/pending or any custom stage as defined",
          },
          {
            blockIcon: <WorkingHourIcon />,
            blockName: "Working Hour Check",
            blockId: NodeSubType.CHECK_WORKING_HOURS,
            description:
              "Check if the time falls within working hour and accordingly define actions such as agent assign etc.",
          },
        ],
      },
      {
        subHeader: "Shopify",
        listOfBlocks: [
          {
            blockIcon: <ShopifyDiscountCodeIcon />,
            blockName: "Discount code",
            blockId: NodeSubType.DISCOUNT_CODE,
            description: "Create static or dynamic discount codes on shopify",
          },
          {
            blockIcon: <ShopifyAddTagIcon />,
            blockName: "Add Order Tags",
            blockId: NodeSubType.SHOPIFY_ADD_TAGS,
            description: "Add a tag against the order in shopify",
          },
          {
            blockIcon: <ShopifyOrderNoteIcon />,
            blockName: "Add Order Notes",
            blockId: NodeSubType.SHOPIFY_ADD_NOTES,
            description: "Add a note against the order in shopify",
          },
          {
            blockIcon: <ShopifyCustomerTagIcon />,
            blockName: "Add Customer Tags",
            blockId: NodeSubType.ADD_CUSTOMER_TAGS,
            description:
              "Add a customer tag against the order on shopify and BIK",
          },
        ],
      },
      {
        subHeader: "Integrations",
        listOfBlocks: [
          {
            blockIcon: <FreshdeskCreateTicketIcon />,
            blockName: "Freshdesk: Create Ticket",
            blockId: NodeSubType.CREATE_TICKET_ON_FRESHDESK,
            description:
              "Create a ticket on Freshdesk. This requires freshdesk integration",
          },
          {
            blockIcon: <RazorpayCreateTicketIcon />,
            blockName: "Razorpay: Payment Link",
            blockId: NodeSubType.CREATE_PAYMENT_LINK_ON_RAZORPAY,
            description: "Create a payment link from razorpay",
          },
          {
            blockIcon: <GoswiftIcon />,
            blockName: "Goswift: Payment Link",
            blockId: NodeSubType.CREATE_PAYMENT_LINK_ON_GOSWIFT,
            description: "Create a payment link from Goswift",
          },
          {
            blockIcon: <JudgeMe />,
            blockName: "Judge Me",
            blockId: NodeSubType.JUDGEME,
            description: "Create a review on Judge me",
          },
        ],
      },
      {
        subHeader: "Google Sheets",
        listOfBlocks: [
          {
            blockIcon: <GoogleSheetAdd />,
            blockName: "Add row",
            blockId: NodeSubType.GOOGLE_SHEET_ADD_ROW,
            description: "Add a new row on google sheet",
          },
          {
            blockIcon: <GoogleSheetUpdate />,
            blockName: "Update row",
            blockId: NodeSubType.GOOGLE_SHEET_UPDATE_ROW,
            description: "Update an existing row on google sheet",
          },
          {
            blockIcon: <GoogleSheetGet />,
            blockName: "Get row data",
            blockId: NodeSubType.GOOGLE_SHEET_GET_ROW_DATA,
            description:
              "Get all column values for a particular row in google sheet",
          },
        ],
      },
      {
        subHeader: "Special actions",
        listOfBlocks: [
          {
            blockIcon: (
              <StoreVariable color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Store to variable",
            blockId: NodeSubType.STORE_VARIABLE,
            description:
              "Copy value of a variable / expression to another variable",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Rest API",
            blockId: NodeSubType.REST_API,
            description:
              "Call a rest api (GET/POST/PUT) to send or receive data",
          },
          {
            blockIcon: <OtherActionIcon />,
            blockName: "Other Actions",
            blockId: NodeSubType.CALL_CUSTOM_API,
            description: "Call a set of pre defined apis in BIK's system",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Call an API",
            blockId: NodeSubType.CALL_API,
            description: "Use this for generating cart and cart links",
          },
        ],
      },
    ],
  },
  Condition: {
    header: "Flow control blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Flow Control",
        listOfBlocks: [
          {
            blockIcon: <DelayIcon />,
            blockName: "Delay",
            blockId: NodeSubType.DELAY,
            description:
              "Add a specific delay (in hours, minutes or days) OR wait till a specific time",
          },
          {
            blockIcon: <Filter />,
            blockName: "Filter",
            blockId: NodeSubType.FILTER,
            description:
              "Define journey branches basis user properties like city, last order date, total count/value of orders etc.",
          },
          {
            blockIcon: <ABTestingIcon color={COLORS.content.warning} />,
            blockName: "AB Testing",
            blockId: NodeSubType.AB_TEST,
            description: "Split the flow to set up AB tests",
          },
          {
            blockIcon: <ConditionIcon />,
            blockName: "Condition",
            blockId: NodeSubType.CONDITION,
            description: "Set up if/else conditions on variables",
          },
          {
            blockIcon: <Evaluate />,
            blockName: "Evaluate",
            blockId: NodeSubType.EVALUATE,
            description:
              "Evaluate or do basic computations like add/subtract/count/div/multiply",
          },
          {
            blockIcon: <OrderFilterIcon />,
            blockName: "Order filter",
            blockId: NodeSubType.ORDER_FILTER,
            description: "Order filter",
          },
        ],
      },
    ],
  },
};

export const sideModalConfigsGmail: {
  [key: string]: Partial<SideModalProps>;
} = {
  Message: {
    header: "Email blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic emails",
        listOfBlocks: [
          {
            blockIcon: <Email color={"#F00"} />,
            blockName: "Template",
            blockId: NodeSubType.EMAIL_TEMP_NEW,
            description: "Select from a list of email templates",
          },
          {
            blockIcon: <Reply />,
            blockName: "Email reply",
            blockId: NodeSubType.GMAIL_REPLY,
            description: "Automate email replies",
          },
          {
            blockIcon: <Email color={"#FF0000"} />,
            blockName: "Email CSAT/NPS",
            blockId: NodeSubType.EMAIL_CSAT,
            automationChannel: "WHATSAPP",
            description:
              "Send NPS or CSAT surveys for Email (1-3,1-5 or 1-10 scale)",
          },
        ],
      },
    ],
  },
  AI: {
    header: "AI blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic Message",
        listOfBlocks: [
          {
            blockIcon: <ClassifyIcon />,
            blockName: "AI Classify",
            blockId: NodeSubType.AI_CLASSIFY,
            description: "Classify customer messages using AI",
          },
          {
            blockIcon: <AiAskIcon />,
            blockName: "AI Ask",
            blockId: NodeSubType.AI_ASK,
            description: "Collect answers to multiple questions using AI",
          },
        ],
      },
    ],
  },
  Action: {
    header: "Action blocks",
    caption: "Select any block to add to the flow",
    subSections: [
      {
        subHeader: "Generic actions",
        listOfBlocks: [
          {
            blockIcon: <AddEvent color={COLORS.background.negative.vibrant} />,
            blockName: "Add Event",
            blockId: NodeSubType.ADD_EVENT,
            description:
              "Add event in BIK data platform which can be then later used for analytics or segmentation",
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Assign Ticket",
            blockId: NodeSubType.ASSIGN_TICKET,
            description:
              "Assign tickets to a specific agent or team with a label (optional)",
          },
          // {
          //   blockIcon: <AddEvent />,
          //   blockName: "Broadcast Events",
          //   blockId: "broadcast_events",
          // },
          {
            blockIcon: <TagIcon />,
            blockName: "Add Ticket Label",
            blockId: NodeSubType.ADD_TICKET_LABEL,
            description: "Add a label to tickets",
          },
          {
            blockIcon: (
              <TicketIcon color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Change Ticket Stage",
            blockId: NodeSubType.CHANGE_TICKET_STAGE,
            description:
              "Change the ticket stage to close/pending or any custom stage as defined",
          },
          {
            blockIcon: <WorkingHourIcon />,
            blockName: "Working Hour Check",
            blockId: NodeSubType.CHECK_WORKING_HOURS,
            description:
              "Check if the time falls within working hour and accordingly define actions such as agent assign etc.",
          },
        ],
      },
      {
        subHeader: "Shopify",
        listOfBlocks: [
          {
            blockIcon: <ShopifyDiscountCodeIcon />,
            blockName: "Discount code",
            blockId: NodeSubType.DISCOUNT_CODE,
            description: "Create static or dynamic discount codes on shopify",
          },
          {
            blockIcon: <ShopifyAddTagIcon />,
            blockName: "Add Order Tags",
            blockId: NodeSubType.SHOPIFY_ADD_TAGS,
            description: "Add a tag against the order in shopify",
          },
          {
            blockIcon: <ShopifyOrderNoteIcon />,
            blockName: "Add Order Notes",
            blockId: NodeSubType.SHOPIFY_ADD_NOTES,
            description: "Add a note against the order in shopify",
          },
          {
            blockIcon: <ShopifyCustomerTagIcon />,
            blockName: "Add Customer Tags",
            blockId: NodeSubType.ADD_CUSTOMER_TAGS,
            description:
              "Add a customer tag against the order on shopify and BIK",
          },
        ],
      },
      {
        subHeader: "Integrations",
        listOfBlocks: [
          {
            blockIcon: <FreshdeskCreateTicketIcon />,
            blockName: "Freshdesk: Create Ticket",
            blockId: NodeSubType.CREATE_TICKET_ON_FRESHDESK,
            description:
              "Create a ticket on Freshdesk. This requires freshdesk integration",
          },
          {
            blockIcon: <RazorpayCreateTicketIcon />,
            blockName: "Razorpay: Payment Link",
            blockId: NodeSubType.CREATE_PAYMENT_LINK_ON_RAZORPAY,
            description: "Create a payment link from razorpay",
          },
          {
            blockIcon: <GoswiftIcon />,
            blockName: "Goswift: Payment Link",
            blockId: NodeSubType.CREATE_PAYMENT_LINK_ON_GOSWIFT,
            description: "Create a payment link from Goswift",
          },
          {
            blockIcon: <JudgeMe />,
            blockName: "Judge Me",
            blockId: NodeSubType.JUDGEME,
            description: "Create a review on Judge me",
          },
        ],
      },
      {
        subHeader: "Google Sheets",
        listOfBlocks: [
          {
            blockIcon: <GoogleSheetAdd />,
            blockName: "Add row",
            blockId: NodeSubType.GOOGLE_SHEET_ADD_ROW,
            description: "Add a new row on google sheet",
          },
          {
            blockIcon: <GoogleSheetUpdate />,
            blockName: "Update row",
            blockId: NodeSubType.GOOGLE_SHEET_UPDATE_ROW,
            description: "Update an existing row on google sheet",
          },
          {
            blockIcon: <GoogleSheetGet />,
            blockName: "Get row data",
            blockId: NodeSubType.GOOGLE_SHEET_GET_ROW_DATA,
            description:
              "Get all column values for a particular row in google sheet",
          },
        ],
      },
      {
        subHeader: "Special actions",
        listOfBlocks: [
          {
            blockIcon: (
              <StoreVariable color={COLORS.background.negative.vibrant} />
            ),
            blockName: "Store to variable",
            blockId: NodeSubType.STORE_VARIABLE,
            description:
              "Copy value of a variable / expression to another variable",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Rest API",
            blockId: NodeSubType.REST_API,
            description:
              "Call a rest api (GET/POST/PUT) to send or receive data",
          },
          {
            blockIcon: <OtherActionIcon />,
            blockName: "Other Actions",
            blockId: NodeSubType.CALL_CUSTOM_API,
            description: "Call a set of pre defined apis in BIK's system",
          },
          {
            blockIcon: <CallApi color={COLORS.background.negative.vibrant} />,
            blockName: "Call an API",
            blockId: NodeSubType.CALL_API,
            description: "Use this for generating cart and cart links",
          },
        ],
      },
    ],
  },
};
