import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WhatsappAddressVariableState } from "./storeDataStateType";

const initialState: WhatsappAddressVariableState = {
  whatsappAddressVariablesList: {},
};

const whatsappAddressVariablesDataSlice = createSlice({
  name: "whatsappAddressVariables",
  initialState: initialState,
  reducers: {
    setAddressVariablesList: (
      state,
      action: PayloadAction<Partial<WhatsappAddressVariableState>>
    ) => {
      const { whatsappAddressVariablesList } = action.payload;
      state.whatsappAddressVariablesList = {
        ...state.whatsappAddressVariablesList,
        ...whatsappAddressVariablesList,
      };
      return state;
    },
    removeFromList: (state, action: PayloadAction<string[]>) => {
      const keys = action.payload;
      keys.forEach((key) => {
        delete state.whatsappAddressVariablesList[key];
      });
      return state;
    },
  },
});

export const whatsappAddressVariablesDataAction =
  whatsappAddressVariablesDataSlice.actions;
export default whatsappAddressVariablesDataSlice;
