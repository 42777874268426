import { Icon } from "./icons-props";

const AddressIcon: Icon = (props) => {
  const { width, height, color, backgroundColor } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 5C15.5523 5 16 5.44772 16 6V8H23C23.2652 8 23.5196 8.10536 23.7071 8.29289L27.7071 12.2929C28.0976 12.6834 28.0976 13.3166 27.7071 13.7071L23.7071 17.7071C23.5196 17.8946 23.2652 18 23 18H16V27C16 27.5523 15.5523 28 15 28C14.4477 28 14 27.5523 14 27V18H7C6.44772 18 6 17.5523 6 17V9C6 8.44772 6.44772 8 7 8H14V6C14 5.44772 14.4477 5 15 5ZM8 10V16H22.5858L25.5858 13L22.5858 10H8Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

export default AddressIcon;
