import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import {
  ChildNodeProps,
  CustomNodeProps,
} from "../../../utilities/flowBuilder.utility";
import { AnalyticsCardRenderer, ChildNodes } from "../customNode";
import { NodeStyle } from "../style";
import { ActionNodeStyle } from "./style";
import { IconMapping } from "../../../utilities/flowBuilder.utility";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../state/store";
import { useGetIntegrationsByStoreQuery } from "../../../state/integrations";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  AnalyticsToPercentageConverter,
  downloadNodeLevelAnalytics,
} from "../../../services/helpers/AnalyticsHelper";
import {
  BodyCaption,
  BodySecondary,
  COLORS,
  IconButton,
  Spinner,
  Tooltip,
} from "@bikdotai/bik-component-library";
import { useDetectAnalytics } from "../useDetectAnalytics";
import { generateCSV } from "../../../utilities/fileDownload";
import DownloadIcon from "../../../icons/DownloadIcon";
import RefreshIcon from "../../../icons/refreshIcon";
import useRetryHook from "../retryHook";
import { notificationDataStateActions } from "../../../state/notificationDataState";
import { homeDataAction } from "../../../state/homeState";
import { Beta } from "../../header-component/headerComponent";
import { BETA_SUBTYPES } from "../../../app/messageBlock/Constants";
import { captureErrorToSentry } from "../../../utilities/sentryHelper";
import { backgroundBasedOnSubType } from "../util";
import ErrorReport from "../../../icons/errorReport";
import { ADMIN_STORE_ID } from "../../../config";
import { SummaryAnalyticsViewTabName } from "../../../components/header/SummaryAnalytics";
import { RecordEventHelper } from "../../../utilities/RecordEventHelpers";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../../utilities/AnalyticsTypes";

const getActionBlockDescription = (subType: string): string => {
  let description: string;
  switch (subType) {
    case "change_ticket_stage":
      description = "Click to change stage";
      break;
    case "check_working_hours":
      description = "Click to add action";
      break;
    case "assign_ticket":
      description = "Click to assign ticket";
      break;
    default:
      description = `Click to add ${subType?.split("_")?.at(-1)}` ?? "";
  }
  return description;
};

const ActionBlockNode = (props: CustomNodeProps) => {
  const {
    nodeData,
    leftHandlerStyle,
    rightHandlerStyle,
    isConnectable,
    editNode,
    onHover,
  } = props;
  const homeState = useAppSelector((state) => state.homeState);
  const [isSSO, setIsSSO] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isErrorDownloading, setIsErrorDownloading] = useState<boolean>(false);
  const [isRetrying, setIsRetrying] = useState<boolean>(false);
  const [actionAnalytics, setActionAnalytics] = useState<{
    [key: string]: string;
  }>({});
  const dispatcher = useDispatch();
  const storeState = useAppSelector((state: any) => state.storeState);
  const {
    data: integrationAppIds,
    error: checkIntegrationsError,
    isLoading: checkIntegrationsLoading,
  } = useGetIntegrationsByStoreQuery(homeState.storeId);
  const subType = nodeData?.subType || "";
  const location = useLocation();
  const isAnalytics = useDetectAnalytics();
  const [isNodePresent, setIsNodePresent] = useState<boolean>(
    storeState?.flow?.[props?.id]
  );
  const [isFallback, setIsFallback] = useState<boolean>(false);
  const { retry } = useRetryHook();

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals(props?.nodeData.nodeId);
  }, [props?.nodeData.childNodes]);

  useEffect(() => {
    if (homeState.userData && homeState.userData?.loginType === "sso") {
      setIsSSO(true);
    }
    if (homeState?.analyticsData?.nodeAnalytics) {
      const triggered =
        homeState?.analyticsData?.nodeAnalytics?.[nodeData.nodeId]
          ?.triggered_count;
      const executed =
        homeState?.analyticsData?.nodeAnalytics?.[nodeData.nodeId]
          ?.executed_count;
      setActionAnalytics(
        nodeData?.subType === "judgeme"
          ? {
              Triggered: triggered > 0 ? triggered : "--",
            }
          : {
              Triggered: triggered > 0 ? triggered : "--",
              Success:
                (executed / triggered) * 100 > 100
                  ? "100%"
                  : (executed / triggered) * 100 > 0
                    ? `${AnalyticsToPercentageConverter(executed, triggered)}%`
                    : "--",
            }
      );
    }
  }, [nodeData, homeState?.analyticsData, location]);

  useEffect(() => {
    const storeStateForNode = storeState?.flow?.[nodeData?.nodeId];
    if (storeStateForNode) {
      setIsNodePresent(true);
      if (storeStateForNode.sub_type === "CSAT/NPS") {
        const isFallback =
          !!storeStateForNode.actions?.csat_nps?.fallback?.fallbackDuration &&
          storeStateForNode.actions?.csat_nps?.fallback?.fallbackV2;
        setIsFallback(isFallback ?? false);
      }
    }
  }, [nodeData]);

  const checkIfIntegrationAvailable = () => {
    const integrationBlocks = [
      "send_sms",
      "send_email",
      "judgeme",
      "create_payment_link_on_Razorpay",
      "create_payment_link_on_Goswift",
      "create_ticket_on_freshdesk",
      "shopify_add_notes",
      "shopify_add_tags",
    ];
    const intergationCodeMappings: any = {
      send_sms: "TRUSTSIGNAL",
      send_email: "EMAIL",
      create_payment_link_on_Razorpay: "RAZORPAY",
      create_payment_link_on_Goswift: "GOSWIFT",
      create_ticket_on_freshdesk: "FRESHDESK",
      judgeme: "JUDGEME",
      shopify_add_notes: "SHOPIFY",
      shopify_add_tags: "SHOPIFY",
    };

    if (!integrationBlocks.includes(subType)) {
      return true;
    }

    if (checkIntegrationsError || checkIntegrationsLoading) {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          integration: {
            errorType: "integrationsNotLoaded",
            nodeId: nodeData?.nodeId,
            name: intergationCodeMappings[subType],
          },
        })
      );
      return false;
    }

    const appIds = [...integrationAppIds, "SHOPIFY"];

    const whiteListedStores = [ADMIN_STORE_ID];

    if (whiteListedStores.includes(homeState.storeId)) {
      return true;
    }

    if (!appIds.includes(intergationCodeMappings[subType])) {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          integration: {
            errorType: "integrationsNotAvailable",
            nodeId: nodeData?.nodeId,
            name: intergationCodeMappings[subType],
          },
        })
      );
      return false;
    }
    return true;
  };

  const onClickEdit = () => {
    if (checkIfIntegrationAvailable()) {
      editNode(
        nodeData?.type,
        props?.id,
        nodeData?.subType,
        nodeData?.nodeIndex,
        nodeData?.automationChannel
      );
    }
  };

  const handleNLADownload = async (e: any, isError = false) => {
    e.stopPropagation();
    if (isError) {
      setIsErrorDownloading(true);
    } else {
      setIsDownloading(true);
    }
    const buttons: { [key: string]: string } = {};
    nodeData?.childNodes?.forEach((item: any) => {
      buttons[item?.buttonId] = item?.name;
    });
    const payload: any = {
      storeId: homeState.storeId,
      flowId: homeState.flowId,
      startDate: homeState.analyticsData?.startDate,
      endDate: homeState.analyticsData?.endDate,
      channel: homeState.channel,
      nodeId: nodeData.nodeId,
      nodeSubType: nodeData.subType,
      nodeType: nodeData.nodeType ?? nodeData.type, // if nodeType is present, give it priority
      basedOnTriggeredDate:
        homeState?.analyticsData?.selectedView ===
        SummaryAnalyticsViewTabName.FUNNEL_VIEW,
    };
    if (isError) {
      payload.onlyErrorReport = true;
    }
    if (nodeData.subType === "CSAT/NPS") {
      payload["buttons"] = buttons;
    } else {
      payload["conditions"] = buttons;
    }
    const response = await downloadNodeLevelAnalytics(payload);
    if (response) {
      generateCSV(
        response,
        `${isError ? "error_" : ""}${homeState.flowId}_${nodeData.nodeId}_${
          nodeData.subType
        }`
      );
      const eventHelper = RecordEventHelper.getInstance();
      eventHelper.trackEvent(
        TargetPlatform.Amplitude,
        AmplitudeKeys.service_used,
        {
          step: isError
            ? AmplitudeEventStep.error_report_downloaded
            : AmplitudeEventStep.node_analytics_downloaded,
          screen: "journey_builder_analytics",
        }
      );
    }
    if (isError) {
      setIsErrorDownloading(false);
    } else {
      setIsDownloading(false);
    }
  };

  const retryNLA = async () => {
    setIsRetrying(true);
    const response = await retry(nodeData);
    if (!response) {
      dispatcher(
        notificationDataStateActions.setNotifications({
          successMessage: "",
          errorMessage: "Something went wrong",
        })
      );
    } else {
      dispatcher(
        notificationDataStateActions.setNotifications({
          successMessage: "Retry Success",
          errorMessage: "",
        })
      );
    }
    setIsRetrying(false);
  };

  const approve = () => {
    if (homeState.retryAccess) {
      retryNLA()
        .then()
        .catch((e) => {
          captureErrorToSentry(e, `Something went wrong actionNode`);
        });
    } else {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          showPwModal: true,
        })
      );
    }
  };

  return (
    <div>
      <ActionNodeStyle
        subType={nodeData?.subType}
        hasChildNodes={!!nodeData?.childNodes?.length}
      >
        {nodeData?.description && (
          <Handle
            id="target"
            type="target"
            position={Position.Left}
            style={leftHandlerStyle}
            isConnectable={isConnectable && !isAnalytics}
          />
        )}
        <NodeStyle
          className="customNode flex--column"
          onMouseEnter={() => onHover(props?.id, nodeData?.subType)}
          onMouseLeave={() => onHover(null, undefined)}
          nodeColor={backgroundBasedOnSubType(
            nodeData?.subType,
            homeState.channel!
          )}
          onClick={isAnalytics ? () => {} : onClickEdit}
        >
          <div className="customNode__header flex justify-content-between align-items-center">
            <div className="flex">
              <span className="customNode__header__icon icon-wrapper">
                {IconMapping(nodeData?.subType, homeState.channel!)}
              </span>
              <div style={{ width: 15 }}></div>
              <span className="customNode__header__title">
                {nodeData?.title || nodeData?.subType}
              </span>
              <div style={{ width: 15 }}></div>
              {BETA_SUBTYPES.includes(nodeData?.subType) && (
                <span className="icon-wrapper">
                  <Beta />
                </span>
              )}
            </div>
            {isAnalytics && (
              <div className={"ml-auto d-flex"}>
                {isSSO && (
                  <div>
                    {isRetrying ? (
                      <div style={{ width: 32 }}>
                        <Spinner size="small" color={COLORS.content.primary} />
                      </div>
                    ) : (
                      <IconButton
                        Icon={RefreshIcon}
                        style={{ color: "#919191", display: "flex" }}
                        onClick={approve}
                      />
                    )}
                  </div>
                )}
                <div>
                  {isDownloading ? (
                    <div style={{ width: 32 }}>
                      <Spinner size="small" color={COLORS.content.primary} />
                    </div>
                  ) : (
                    /* @ts-ignore */
                    <Tooltip
                      delay={[200, 0]}
                      body="Download node analytics"
                      placement="right"
                    >
                      <IconButton
                        Icon={DownloadIcon}
                        style={{ color: "#919191", display: "flex" }}
                        onClick={handleNLADownload}
                      />
                    </Tooltip>
                  )}
                </div>
                <div>
                  {isErrorDownloading ? (
                    <div style={{ width: 32 }}>
                      <Spinner size="small" color={COLORS.content.primary} />
                    </div>
                  ) : (
                    /* @ts-ignore */
                    <Tooltip
                      delay={[200, 0]}
                      body="Download error report"
                      placement="right"
                    >
                      <IconButton
                        Icon={ErrorReport}
                        style={{ color: "#919191", display: "flex" }}
                        onClick={(e) => handleNLADownload(e, true)}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="customNode__body flex--column">
            {!nodeData?.description && !nodeData?.childNodes?.length && (
              <div className="customNode__body__no-content flex">
                {getActionBlockDescription(nodeData?.subType)}
              </div>
            )}
            {nodeData?.description && (
              <div className="customNode__body__description">
                <BodySecondary
                  className="two-lines"
                  color={COLORS.content.primary}
                >
                  {nodeData?.description}
                </BodySecondary>
                {nodeData?.subHeader && (
                  <BodyCaption
                    className="multi-line"
                    style={{ marginTop: 2 }}
                    color={COLORS.content.secondary}
                  >
                    {nodeData?.subHeader}
                  </BodyCaption>
                )}
              </div>
            )}
          </div>
          <div className="customNode__footer">
            {!!nodeData?.childNodes?.length && (
              <div className="customNode__footer__child-nodes flex--column">
                {nodeData.childNodes.map(
                  (child: ChildNodeProps, index: number) => (
                    <div className="customNode__childNodes">
                      <ChildNodes
                        key={child.name}
                        name={child.name}
                        buttonId={child.buttonId}
                        index={index}
                        connectorColor={nodeData?.connectorColor}
                        nodeType={"Action"}
                        count={nodeData.childNodes.length}
                        isConnectable={child.isConnectable}
                        nodeId={nodeData?.nodeId}
                        subType={nodeData.subType}
                      />
                    </div>
                  )
                )}
              </div>
            )}
            {(nodeData?.subType === "CSAT" ||
              nodeData?.subType === "email_csat" ||
              (!nodeData?.childNodes?.length &&
                nodeData?.subType !== "start_flow")) &&
              isNodePresent && (
                <div className="customNode__footer__next-step">Next step</div>
              )}
            {isFallback && (
              <div className="customNode__footer__next-step">Fallback</div>
            )}
          </div>
        </NodeStyle>
        {(nodeData?.subType === "CSAT" ||
          nodeData?.subType === "email_csat" ||
          (!nodeData?.childNodes?.length &&
            nodeData?.subType !== "start_flow")) &&
          isNodePresent && (
            <Handle
              type="source"
              position={Position.Right}
              id="source"
              style={{ ...leftHandlerStyle, ...rightHandlerStyle }}
              isConnectable={isConnectable && !isAnalytics}
            />
          )}
        {isFallback && (
          <Handle
            type="source"
            position={Position.Right}
            id="fallback"
            style={{ ...leftHandlerStyle, ...rightHandlerStyle }}
            isConnectable={isConnectable && !isAnalytics}
          />
        )}
      </ActionNodeStyle>
      {isAnalytics &&
        AnalyticsCardRenderer(
          actionAnalytics,
          "100%",
          homeState?.analyticsData?.shimmer
        )}
    </div>
  );
};

export default ActionBlockNode;

const SpecialActions: { [key: string]: boolean } = {
  evaluate: true,
  store_variable: true,
  call_api: true,
  call_custom_api: true,
};
