import { COLORS } from "@bikdotai/bik-component-library";
import styled from "styled-components";

export const AutomationTriggerStyle = styled.div`
  .modal-wrapper {
    padding: 20px;
  }

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
  }

  .desc {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
  }

  .trigger-select {
    margin-top: 16px;
    display: flex;
    button {
      .icon-leading {
        display: flex;
      }
    }
  }

  h2 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    line-height: 0.1em;
    margin: 24px 0 20px;
  }

  h2 span {
    background: #fafafa;
    padding: 0 10px;
    color: #616161;
    font-weight: 400;
    font-size: 12px;
    line-height: 0px;
  }
  .trigger-list {
    padding-top: 15px;
  }
  .event-warning {
    position: fixed;
    bottom: 72px;
    left: 0;
    right: 0;
    width: 424px;
    z-index: 2;
  }

  .delete-block {
    height: 32px;
    width: 32px;
    border-radius: 4px;
    margin-left: 8px;
    background-color: ${COLORS.surface.standard};
    border: 1px solid ${COLORS.stroke.primary};
    display: flex;
  }
`;

export const PopOverItem = styled.button`
  width: 100%;
  border: none;
  background-color: ${COLORS.surface.standard};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: ${COLORS.surface.subdued};
  }
  &:active {
    background-color: ${COLORS.background.brandLight};
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background-color: ${COLORS.stroke.primary};
`;

export const SectionDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-color: ${COLORS.stroke.primary};
`;
