import { captureErrorToSentry } from "../../utilities/sentryHelper";
import { Variable, VariableListInterfaceV2 } from "./VariablesListHookV2";
import { cloneDeep } from "lodash";
import { NodeSubType } from "../../models/nodeSubType";

const mapping: any = {
  ask_name: ["customer.name"],
  ask_email: ["customer.email"],
  ask_phone: ["customer.phone"],
  catalog_list: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
  order_list: [
    {
      name: "Order variables",
      subCategory: [
        "Basic",
        "Product Details",
        "Address",
        "Payment Details",
        "Shipment",
      ],
    },
  ],
  single_product: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
  product_card: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
  multi_product: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
  product_carousel: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
  browse_collection: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
  add_to_checkout: ["checkout.checkoutUrl", "checkout.customCheckoutItems"],
  add_wapp_cart_to_checkout: ["checkout.checkoutUrl", "checkout.items"],
  add_customer_tags: ["customer.tags"],
  add_to_cart: ["checkout.checkoutUrl", "checkout.customCheckoutItems"],
  wa_carousel: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
};

const event_mapping: any = {
  lineItemShippingUpdate: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations", "Shipment"],
    },
    {
      name: "Order variables",
      subCategory: [
        "Basic",
        "Product Details",
        "Address",
        "Payment Details",
        "Shipment",
      ],
    },
  ],
  ordered: [
    {
      name: "Order variables",
      subCategory: [
        "Basic",
        "Product Details",
        "Address",
        "Payment Details",
        "Shipment",
      ],
    },
  ],
  abandonedCartCreated: [
    {
      name: "Abandoned cart variables",
      subCategory: ["Basic", "First Product"],
    },
  ],
  purchasedAProduct: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
    {
      name: "Order variables",
      subCategory: ["Basic", "Product Details", "Address", "Payment Details"],
    },
  ],
  paymentLinkUpdate: [
    {
      name: "Payment variables",
      subCategory: ["Payment Details"],
    },
  ],
  abandonedAProduct: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
    {
      name: "Abandoned cart variables",
      subCategory: ["Basic", "First Product"],
    },
  ],
  addedToWishlist: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
  productViewed: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
  orderCancelled: [
    {
      name: "Order variables",
      subCategory: [
        "Basic",
        "Product Details",
        "Address",
        "Payment Details",
        "Shipment",
      ],
    },
  ],
  productDelivered: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations", "Shipment"],
    },
    {
      name: "Order variables",
      subCategory: [
        "Basic",
        "Product Details",
        "Address",
        "Payment Details",
        "Shipment",
      ],
    },
  ],
  addToCart: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
  abandonedProduct: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
  orderDelivered: [
    {
      name: "Order variables",
      subCategory: [
        "Basic",
        "Product Details",
        "Address",
        "Payment Details",
        "Shipment",
      ],
    },
  ],
};

const business_event_mapping: any = {
  businessEventNotification: [
    {
      name: "Product variables",
      subCategory: ["Basic", "Recommendations"],
    },
  ],
};

const variableNodeIdMapping: any = {};

const populateVariablesToBeEnabled = (
  availableVariables: any,
  variablesList: VariableListInterfaceV2[]
) => {
  let variablesToBeEnabled: string[] = [];
  availableVariables.forEach((category: any) => {
    if (typeof category === "string") {
      // direct variable ex - ['customer.name']
      variablesToBeEnabled.push(category);
    } else {
      const name = category.name;
      const subCategories = category.subCategory;
      const match = variablesList.find(
        (variable) => variable.displayName === name
      );
      if (match) {
        Object.keys(match.variables).forEach((key) => {
          if (subCategories.includes(key)) {
            const variableToAdd: Variable = match.variables[key];
            if (variableToAdd.isSubHeader) {
              Object.keys(variableToAdd.variables!).forEach((subHeaderKey) => {
                const subHeaderVariableToAdd: Variable =
                  variableToAdd.variables![subHeaderKey];
                if (typeof subHeaderVariableToAdd.actualValue !== "undefined") {
                  variablesToBeEnabled.push(
                    subHeaderVariableToAdd.actualValue
                      .replace("{{", "")
                      .replace("}}", "")
                  );
                }
              });
            }
            if (typeof variableToAdd.actualValue !== "undefined") {
              variablesToBeEnabled.push(
                variableToAdd.actualValue.replace("{{", "").replace("}}", "")
              );
            }
          }
        });
      }
    }
  });
  return variablesToBeEnabled;
};

export function onNodeAdd(
  nodeId: string,
  nodeConfig: any,
  setVariablesList: any,
  variablesList: VariableListInterfaceV2[],
  sub_type?: string,
  channel?: string,
  selectedHeaders?: string[]
) {
  const nodeSubType = sub_type ? sub_type : nodeConfig.sub_type;

  switch (nodeSubType) {
    case NodeSubType.CHANNEL:
      const channelVariablesToBeEnabled: string[] = [];
      //   channelVariablesToBeEnabled.push("customer.id"); deleted as per the new requirement
      channelVariablesToBeEnabled.push("customer.firstName");
      if (channel) {
        if (channel === "whatsapp") {
          channelVariablesToBeEnabled.push("customer.phone");
          channelVariablesToBeEnabled.push("customer.name");
        } else if (channel === "instagram") {
          channelVariablesToBeEnabled.push("customer.instagramUsername");
          channelVariablesToBeEnabled.push("store.igProfileImage");
        } else if (["gmail", "email"].includes(channel)) {
          channelVariablesToBeEnabled.push("customer.email");
          channelVariablesToBeEnabled.push("helpdesk.signature");
        }
      }
      activateVariablesIfAvailable(
        nodeId,
        setVariablesList,
        variablesList,
        channelVariablesToBeEnabled
      );
      break;
    case NodeSubType.STARTING_POINT:
      handleStartingPoint(
        nodeId,
        nodeConfig,
        setVariablesList,
        variablesList,
        selectedHeaders
      );
      break;
    case NodeSubType.STORE_VARIABLE:
      handleDeactivateVariables(nodeId, setVariablesList, variablesList);
      let variablesToBeEnabled: string[] = [];
      if (nodeConfig.actions.store_value) {
        variablesToBeEnabled = getVariablesForStoreVariableQuestionSubType(
          nodeConfig.actions.store_value.field,
          variablesList
        );
      } else if (nodeConfig.actions.store_value_evaluation) {
        variablesToBeEnabled = getVariablesForStoreVariableQuestionSubType(
          nodeConfig.actions.store_value_evaluation.field,
          variablesList
        );
      }
      activateVariablesIfAvailable(
        nodeId,
        setVariablesList,
        variablesList,
        variablesToBeEnabled
      );
      break;
    case NodeSubType.QUESTION:
      handleDeactivateVariables(nodeId, setVariablesList, variablesList);
      let variablesToBeEnabledForQuestion: string[] = [];
      if (nodeConfig.message_template.var_name) {
        variablesToBeEnabledForQuestion =
          getVariablesForStoreVariableQuestionSubType(
            nodeConfig.message_template.var_name,
            variablesList
          );
      }
      activateVariablesIfAvailable(
        nodeId,
        setVariablesList,
        variablesList,
        variablesToBeEnabledForQuestion
      );
      break;
    case NodeSubType.CALL_API:
      // take action based on mapping
      const variables = mapping[nodeConfig.actions.call_api.api_name];
      // activate these variables
      handleDeactivateVariables(nodeId, setVariablesList, variablesList);
      activateVariablesIfAvailable(
        nodeId,
        setVariablesList,
        variablesList,
        variables
      );
      break;
    case NodeSubType.PRODUCT_CARD:
      handleDeactivateVariables(nodeId, setVariablesList, variablesList);
      // take action based on mapping
      let productCardVariables = mapping[nodeConfig.sub_type];
      let productVariablesToBeEnabled: any[] = populateVariablesToBeEnabled(
        productCardVariables,
        variablesList
      );
      for (const key of Object.keys(nodeConfig.template)) {
        if (
          nodeConfig.template[key]?.sub_type === "call_api" &&
          nodeConfig.template[key]?.actions?.call_api?.api_name ===
            "add_to_checkout"
        ) {
          productVariablesToBeEnabled = [
            ...productVariablesToBeEnabled,
            ...mapping["add_to_cart"],
          ];
          break;
        }
      }
      // activate these variables
      activateVariablesIfAvailable(
        nodeId,
        setVariablesList,
        variablesList,
        productVariablesToBeEnabled
      );
      break;
    default:
      // take action based on mapping
      const mappedVariables = mapping[nodeConfig.sub_type];
      if (!mappedVariables) {
        return;
      }
      const variablesDefault = populateVariablesToBeEnabled(
        mappedVariables,
        variablesList
      );
      // activate these variables
      activateVariablesIfAvailable(
        nodeId,
        setVariablesList,
        variablesList,
        variablesDefault
      );
      break;
  }
}

export function onNodeDelete(
  nodeConfig: any,
  setVariablesList: any,
  variablesList: VariableListInterfaceV2[]
) {
  try {
    switch (nodeConfig.subType) {
      case NodeSubType.STORE_VARIABLE:
      case NodeSubType.QUESTION:
        handleDeactivateVariables(
          nodeConfig.nodeId,
          setVariablesList,
          variablesList
        );
        break;
      case NodeSubType.CALL_API:
        handleDeactivateVariables(
          nodeConfig.nodeId,
          setVariablesList,
          variablesList
        );
        break;
      case NodeSubType.PRODUCT_CARD:
        handleDeactivateVariables(
          nodeConfig.nodeId,
          setVariablesList,
          variablesList
        );
        break;
      default:
        // take action based on mapping
        const mappedVariables = mapping[nodeConfig.subType];
        if (!mappedVariables) {
          return;
        }
        const variables = populateVariablesToBeEnabled(
          mappedVariables,
          variablesList
        );
        if (!variables) {
          return;
        }
        const filterVariables: string[] = [];
        variables.forEach((variable: any) => {
          const nodeIds = variableNodeIdMapping[variable];
          if (!nodeIds) {
            return;
          }
          const filterNodeIds = nodeIds.filter(
            (id: string) => id != nodeConfig.nodeId
          );
          if (!filterNodeIds.length) {
            // deactivate this variable
            filterVariables.push(variable);
          }
          variableNodeIdMapping[variable] = filterNodeIds;
        });
        deactivateVariablesIfAvailable(
          setVariablesList,
          variablesList,
          filterVariables
        );
        break;
    }
  } catch (e) {
    captureErrorToSentry(e, `Error in onNodeDelete, NodeConfig`);
  }
}

function getVariablesForStoreVariableQuestionSubType(
  variable: string,
  variablesList: VariableListInterfaceV2[]
): string[] {
  if (variable.includes(".id")) {
    let payloadVariables: any = undefined;
    if (variable === "customer.id") {
      payloadVariables = variablesList.find(
        (variables) => variables.displayName === "Customer variables"
      )!;
    } else if (variable === "cart.id") {
      payloadVariables = variablesList.find(
        (variables) => variables.displayName === "Abandoned cart variables"
      )!;
    } else if (variable === "order.id") {
      payloadVariables = variablesList.find(
        (variables) => variables.displayName === "Order variables"
      )!;
    } else if (variable === "product.id") {
      payloadVariables = variablesList.find(
        (variables) => variables.displayName === "Product variables"
      )!;
      delete payloadVariables.variables["Shipment"];
    } else if (variable === "catalog.id") {
      payloadVariables = variablesList.find(
        (variables) => variables.displayName === "Catalog variables"
      )!;
    }

    if (!payloadVariables?.variables) {
      return [];
    }
    const variablesToFetch: string[] = [];
    Object.keys(payloadVariables.variables).forEach((key) => {
      const variableToAdd: Variable = payloadVariables.variables[key];
      if (variableToAdd?.isSubHeader && variableToAdd?.variables) {
        Object.keys(variableToAdd.variables!).forEach((subHeaderKey) => {
          const subHeaderVariableToAdd: Variable =
            variableToAdd.variables![subHeaderKey];
          if (typeof subHeaderVariableToAdd.actualValue !== "undefined") {
            variablesToFetch.push(
              subHeaderVariableToAdd.actualValue
                .replace("{{", "")
                .replace("}}", "")
            );
          }
        });
      }
      if (typeof variableToAdd.actualValue !== "undefined") {
        variablesToFetch.push(
          variableToAdd.actualValue.replace("{{", "").replace("}}", "")
        );
      }
    });
    return variablesToFetch;
  } else {
    return [variable];
  }
}

export function activateVariablesIfAvailable(
  nodeId: string,
  setVariablesList: any,
  variablesList: VariableListInterfaceV2[],
  variableList: string[]
) {
  if (!variableList || variableList.length === 0) {
    return;
  }
  const newList = variablesList.map((variables) => {
    const headerVariables = variables.variables ?? {};
    variableList.forEach((variableStr) => {
      Object.keys(headerVariables).forEach((key) => {
        const headerVariable = headerVariables[key] as Variable;
        if (headerVariable?.isSubHeader && headerVariable?.variables) {
          Object.keys(headerVariable.variables!).forEach((subHeaderKey) => {
            if (headerVariable.variables) {
              const subHeaderVariable = headerVariable.variables[subHeaderKey];
              if (
                subHeaderVariable.actualValue
                  .replace("{{", "")
                  .replace("}}", "") === variableStr
              ) {
                subHeaderVariable.isAvailable = true;
                headerVariable.variables[subHeaderKey] = subHeaderVariable;
                headerVariable.isAvailable = true;
                if (!variableNodeIdMapping[variableStr]) {
                  variableNodeIdMapping[variableStr] = [];
                }
                if (
                  !variableNodeIdMapping[variableStr].find(
                    (id: string) => id === nodeId
                  )
                ) {
                  variableNodeIdMapping[variableStr].push(nodeId);
                }
              }
            }
          });
          headerVariables[key] = headerVariable;
        } else if (
          headerVariable.actualValue.replace("{{", "").replace("}}", "") ===
          variableStr
        ) {
          headerVariable.isAvailable = true;
          headerVariables[key] = headerVariable;
          if (!variableNodeIdMapping[variableStr]) {
            variableNodeIdMapping[variableStr] = [];
          }
          if (
            !variableNodeIdMapping[variableStr].find(
              (id: string) => id === nodeId
            )
          ) {
            variableNodeIdMapping[variableStr].push(nodeId);
          }
        }
      });
    });
    variables.variables = headerVariables;
    return variables;
  });
  setVariablesList(newList);
}

function deactivateVariablesIfAvailable(
  setVariablesList: any,
  variablesList: VariableListInterfaceV2[],
  variableList: string[]
) {
  const newList = variablesList.map((variables) => {
    const headerVariables = variables.variables ?? {};
    variableList.forEach((variableStr) => {
      Object.keys(headerVariables).forEach((key) => {
        const headerVariable = headerVariables[key] as Variable;
        if (headerVariable.isSubHeader) {
          let count = 0;
          Object.keys(headerVariable.variables!).forEach((subHeaderKey) => {
            if (headerVariable.variables) {
              const subHeaderVariable = headerVariable.variables[subHeaderKey];
              if (
                subHeaderVariable.actualValue
                  .replace("{{", "")
                  .replace("}}", "") === variableStr
              ) {
                subHeaderVariable.isAvailable = false;
                headerVariable.variables[subHeaderKey] = subHeaderVariable;
              }
              if (!subHeaderVariable.isAvailable) {
                count++;
              }
            }
          });
          if (Object.keys(headerVariable.variables!).length === count) {
            headerVariable.isAvailable = false;
          }
          headerVariables[key] = headerVariable;
        } else if (
          headerVariable.actualValue.replace("{{", "").replace("}}", "") ===
          variableStr
        ) {
          headerVariable.isAvailable = false;
          headerVariables[key] = headerVariable;
        }
      });
    });
    variables.variables = headerVariables;
    return variables;
  });
  setVariablesList(newList);
}

const activateFlowVariables = (
  nodeId: string,
  setVariablesList: any,
  variablesList: VariableListInterfaceV2[],
  varsToBeEnabled?: string[]
) => {
  const variablesToBeEnabled: string[] = [];
  variablesToBeEnabled.push("flowVariables.flowTriggerInput", "flowVariables.flowTriggeredRawInput");
  if (varsToBeEnabled) {
    variablesToBeEnabled.push(...varsToBeEnabled);
  }
  activateVariablesIfAvailable(
    nodeId,
    setVariablesList,
    variablesList,
    variablesToBeEnabled
  );
};

const handleStartingPoint = (
  nodeId: string,
  nodeConfig: any,
  setVariablesList: any,
  variablesList: VariableListInterfaceV2[],
  selectedHeaders?: string[]
) => {
  handleDeactivateVariables(nodeId, setVariablesList, variablesList);
  if (!nodeConfig || !nodeConfig.triggerType) {
    activateFlowVariables(nodeId, setVariablesList, variablesList);
    return;
  }
  if (nodeConfig.triggerType === "keyword") {
    //deActivateTicketVariables(nodeId, setVariablesList, variablesList);
    const payloadVariables = variablesList.find(
      (variables) => variables.displayName === "Flow variables"
    )!;
    if (!payloadVariables?.variables) {
      return;
    }
    const variablesToBeEnabled: string[] = [];

    Object.keys(payloadVariables.variables).forEach((key) => {
      if (key === "Basic") {
        let variableToAdd: Variable = payloadVariables.variables[key];
        if (variableToAdd?.isSubHeader && variableToAdd?.variables) {
          Object.keys(variableToAdd.variables!).forEach((subHeaderKey) => {
            const subHeaderVariableToAdd: Variable =
              variableToAdd.variables![subHeaderKey];
            if (typeof subHeaderVariableToAdd.actualValue !== "undefined") {
              variablesToBeEnabled.push(
                subHeaderVariableToAdd.actualValue
                  .replace("{{", "")
                  .replace("}}", "")
              );
            }
          });
        }
        if (typeof variableToAdd.actualValue !== "undefined") {
          variablesToBeEnabled.push(
            variableToAdd.actualValue.replace("{{", "").replace("}}", "")
          );
        }
      } else {
        if (nodeConfig.channel === "instagram") {
          variablesToBeEnabled.push("flowVariables.dm");
        }
      }
    });

    activateVariablesIfAvailable(
      nodeId,
      setVariablesList,
      variablesList,
      variablesToBeEnabled
    );
  } else if (["event", "businessEvents"].includes(nodeConfig.triggerType)) {
    const variablesHeaderList: any = [];
    let isEmailTrigger = false;
    nodeConfig.triggers.forEach((trigger: any) => {
      if (trigger.header === "emailReceived") {
        isEmailTrigger = true;
      }
      const varList =
        nodeConfig.triggerType === "event"
          ? event_mapping[trigger.header]
          : business_event_mapping["businessEventNotification"];
      if (varList && varList.length > 0) {
        varList.forEach((category: any) => {
          const subCategory = category.subCategory;
          subCategory.forEach((subCat: any) => {
            variablesHeaderList.push(`${category.name}.${subCat}`);
          });
        });
      }
    });

    if (variablesHeaderList.length === 0 && !isEmailTrigger) {
      return;
    }
    const uniqueVariablesToEnabled: any = Array.from(
      new Set(variablesHeaderList)
    );
    const variablesToBeEnabled: string[] = [];
    uniqueVariablesToEnabled.forEach((header: string) => {
      const category = header.split(".")[0];
      const subCategory = header.split(".")[1];
      const payloadVariables = variablesList.find(
        (variables) => variables.displayName === category
      );
      if (payloadVariables && payloadVariables?.variables) {
        Object.keys(payloadVariables.variables).forEach((key) => {
          if (key === subCategory) {
            const variableToAdd: Variable = payloadVariables.variables[key];
            if (variableToAdd.isSubHeader && variableToAdd?.variables) {
              Object.keys(variableToAdd.variables!).forEach((subHeaderKey) => {
                const subHeaderVariableToAdd: Variable =
                  variableToAdd.variables![subHeaderKey];
                if (typeof subHeaderVariableToAdd.actualValue !== "undefined") {
                  variablesToBeEnabled.push(
                    subHeaderVariableToAdd.actualValue
                      .replace("{{", "")
                      .replace("}}", "")
                  );
                }
              });
            }
            if (typeof variableToAdd.actualValue !== "undefined") {
              variablesToBeEnabled.push(
                variableToAdd.actualValue.replace("{{", "").replace("}}", "")
              );
            }
          }
        });
      }
    });
    if (nodeConfig.triggerType === "event") {
      variablesToBeEnabled.push("ticket.id");
    }

    if (isEmailTrigger) {
      variablesToBeEnabled.push("session.emailHistory");
    }

    activateVariablesIfAvailable(
      nodeId,
      setVariablesList,
      variablesList,
      variablesToBeEnabled
    );
  } else if (nodeConfig.triggerType === "webhook") {
    if (!selectedHeaders) {
      return;
    }
    if (selectedHeaders.length === 0) {
      return;
    }
    const uniqueVariablesToEnabled: any = Array.from(new Set(selectedHeaders));
    const variablesToBeEnabled: string[] = [];
    uniqueVariablesToEnabled.forEach((header: string) => {
      const payloadVariables = variablesList.find(
        (variables) => variables.displayName === header
      );
      if (payloadVariables && payloadVariables?.variables) {
        Object.keys(payloadVariables.variables).forEach((key) => {
          const variableToAdd: Variable = payloadVariables.variables[key];
          if (variableToAdd.isSubHeader && variableToAdd?.variables) {
            Object.keys(variableToAdd.variables!).forEach((subHeaderKey) => {
              const subHeaderVariableToAdd: Variable =
                variableToAdd.variables![subHeaderKey];
              if (typeof subHeaderVariableToAdd.actualValue !== "undefined") {
                variablesToBeEnabled.push(
                  subHeaderVariableToAdd.actualValue
                    .replace("{{", "")
                    .replace("}}", "")
                );
              }
            });
          }
          if (typeof variableToAdd.actualValue !== "undefined") {
            variablesToBeEnabled.push(
              variableToAdd.actualValue.replace("{{", "").replace("}}", "")
            );
          }
        });
      }
    });
    activateVariablesIfAvailable(
      nodeId,
      setVariablesList,
      variablesList,
      variablesToBeEnabled
    );
  } else if (
    nodeConfig.triggerType === "ig" ||
    nodeConfig.triggerType === "ctwa"
  ) {
    const toEnableVars: string[] = [];
    if (nodeConfig.triggerType === "ig") {
      toEnableVars.push("flowVariables.caption");
      toEnableVars.push("flowVariables.products");

      nodeConfig.triggers.forEach((trigger: any) => {
        if (
          ["Comments on post", "Comments on reel", "Comments on ads"].includes(
            trigger.header
          )
        ) {
          toEnableVars.push("flowVariables.comment");
        }

        if ("Replies on story" === trigger.header) {
          toEnableVars.push("flowVariables.storyMessage");
        }

        if (
          ["Direct message on ads", "Direct message on post"].includes(
            trigger.header
          )
        ) {
          toEnableVars.push("flowVariables.dm");
        }
      });
    }

    activateFlowVariables(
      nodeId,
      setVariablesList,
      variablesList,
      toEnableVars
    );
  }
};

export const handleDeactivateVariables = (
  nodeId: string,
  setVariablesList: any,
  variablesList: VariableListInterfaceV2[]
) => {
  const variableNodeIdMappingCopy = cloneDeep(variableNodeIdMapping);
  const filterVariablesToBeDeleted: string[] = [];
  Object.keys(variableNodeIdMappingCopy).forEach((key) => {
    const nodeIds = variableNodeIdMappingCopy[key];
    const filterNodeIds = nodeIds.filter((id: string) => id != nodeId);
    if (!filterNodeIds.length) {
      // deactivate this variable
      filterVariablesToBeDeleted.push(key);
    }
    variableNodeIdMapping[key] = filterNodeIds;
  });
  deactivateVariablesIfAvailable(
    setVariablesList,
    variablesList,
    filterVariablesToBeDeleted
  );
};
